import { gql } from 'react-apollo';

import history from '../../core/history';
import {
  BOOKING_PROCESS_START,
  BOOKING_PROCESS_SUCCESS,
  BOOKING_PROCESS_ERROR,
} from '../../constants';
import getPaymentMethodsQuery from '../../components/Payout/PayoutForm/getPaymentMethods.graphql';
import showToaster from '../../helpers/showToaster';
import { reset } from 'redux-form';

export function bookingProcess(listId, guests, startDate, endDate, preApprove, restrictEdit, threadId, visitors, pets, infants) {




  return async (dispatch, getState, { client }) => {

    dispatch({
      type: BOOKING_PROCESS_START,
      payload: {
        bookingLoading: true
      }
    });

    try {

      if(preApprove == 'request') {
        let account = getState().account.data;
        let host = getState().form.ContactHostForm.values.host;
        let content = '';
        let personCapacity = guests;

        let mutation = gql`
            mutation CreateThreadItems(
              $listId: Int!, 
              $host: String!,
              $content: String!,
              $type: String,
              $startDate: String,
              $endDate: String,
              $personCapacity: Int,
              $visitors: Int,
              $pets: Int,
              $infants: Int
            ){
                CreateThreadItems(
                  listId: $listId,
                  host: $host,
                  content: $content,
                  type: $type,
                  startDate: $startDate,
                  endDate: $endDate,
                  personCapacity: $personCapacity,
                  visitors: $visitors,
                  pets: $pets,
                  infants: $infants
                ) {
                    id
                    threadId
                    sentBy
                    content
                    type
                    startDate
                    endDate
                    personCapacity
                    visitors
                    pets
                    infants
                    createdAt
                    status
                }
            }
        `;

        // Send Message
        const { data } = await client.mutate({
          mutation,
          variables: {
            listId,
            host,
            content,
            type: 'requestToBook',
            startDate,
            endDate,
            personCapacity,
            visitors,
            pets,
            infants
          }
        });

        if (data?.CreateThreadItems?.status == "400") {
          dispatch({
            type: BOOKING_PROCESS_ERROR,
            payload: {
              bookingLoading: false
            }
          });
          showToaster({ messageId: 'requestToBookError', toasterType: 'error' })
        } else if (data?.CreateThreadItems) {
          dispatch({
            type: BOOKING_PROCESS_SUCCESS,
            payload: {
              bookingLoading: false
            }
          });
          dispatch(reset("BookingForm"));
          showToaster({ messageId: 'contactHost', toasterType: 'success' })
        }
      } else {

      let query = gql`
          query UserListing($listId:String!) {
            UserListing (listId:$listId) {
              id
              userId
              title
              coverPhoto
              country
              city
              state
              personCapacity
              bookingType
              reviewsCount
              reviewsStarRating
              beds
              listPhotos{
                id
                name
              }
              user {
                id
                email
                profile{
                  profileId
                  displayName
                  firstName
                  picture
                }
              }
              settingsData {
                id
                settingsId
                listsettings {
                  id
                  itemName
                  settingsType {
                    typeName
                  }
                }
              }
              houseRules {
                houseRulesId
                listsettings{
                  itemName
                  isEnable
                  settingsType {
                    typeName
                  }
                }
              }
              listingData {
                checkInStart,
                checkInEnd,
                basePrice,
                cleaningPrice,
                tax,
                currency,
                weeklyDiscount,
                monthlyDiscount,
                taxRate
                cancellation {
                  id
                  policyName
                  policyContent
                },
                guestBasePrice,
                additionalPrice,
                visitorsLimit,
                visitorsPrice,
                petLimit,
                petPrice,
                infantLimit,
                infantPrice,
              }
              listBlockedPrice {
                id
                listId
                isSpecialPrice
                blockedDates
              }
            }
        }
      `;

      const { data } = await client.query({
        query,
        variables: {
          listId
        },
      });

      let payPalPayment = true, stripePayment = true, razorpayPayment = true;
      const getPaymentMethods = await client.query({
        query: getPaymentMethodsQuery,
        fetchPolicy: 'network-only',
      });

      getPaymentMethods?.data?.getPaymentMethods && getPaymentMethods?.data?.getPaymentMethods?.results && getPaymentMethods?.data?.getPaymentMethods?.results?.length > 0 && getPaymentMethods?.data?.getPaymentMethods?.results.map((item, index) => {
        if (!item.isEnable && item.id == 1) payPalPayment = false;
        // if (!item.isEnable && item.id == 2) stripePayment = false;
        if (!item.isEnable && item.id == 3) razorpayPayment = false;
      });

      if (data?.UserListing) {
        dispatch({
          type: BOOKING_PROCESS_SUCCESS,
          payload: {
            data: data?.UserListing,
            bookDetails: {
              guests,
              startDate,
              endDate,
              visitors,
              pets,
              infants,
              preApprove,
              threadId
            },
            bookingLoading: false,
            payPalPayment,
            // stripePayment,
            razorpayPayment,
            restrictEdit
          }
        });
        history.push('/book/' + listId);
      }
    }

    } catch (error) {
      dispatch({
        type: BOOKING_PROCESS_ERROR,
        payload: {
          error,
          bookingLoading: false
        }
      });
      return false;
    }

    return true;
  };
}

