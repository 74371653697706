import { gql } from 'react-apollo';
import {
  BOOKING_PAYMENT_START,
  BOOKING_PAYMENT_SUCCESS,
  BOOKING_PAYMENT_ERROR,
} from '../../constants';
import { sendPayment } from '../../core/payment/sendPayment';
import { convert } from '../../helpers/currencyConvertion';
import { processRazorpayPayment } from '../../core/payment/razorpay/processRazorpayPayment';
import showToaster from '../../helpers/showToaster';

export function makePayment(
  listId,
  title,
  hostId,
  guestId,
  checkIn,
  checkOut,
  guests,
  message,
  basePrice,
  cleaningPrice,
  taxPrice,
  currency,
  discount,
  discountType,
  guestServiceFee,
  hostServiceFee,
  total,
  bookingType,
  paymentCurrency,
  paymentType,
  guestEmail,
  specialPricing,
  isSpecialPriceAssigned,
  isSpecialPriceAverage,
  dayDifference,
  paymentMethodId,
  taxRate,
  checkInStart,
  checkInEnd,
  hostServiceFeeType,
  hostServiceFeeValue,
  threadId,
  visitors,
  visitorsPrice,
  pets,
  petPrice,
  infants,
  infantPrice,
  additionalGuest,
  additionalPrice
) {
  return async (dispatch, getState, { client }) => {
    dispatch({ type: BOOKING_PAYMENT_START, payload: { paymentLoading: true } });

    try {
      // Fetch cancellationPolicy from state or set a default value
      const cancellationPolicy = getState().book.data.listingData.cancellation.id;

      const mutation = gql`
        mutation createReservation(
          $listId: Int!, 
          $hostId: String!,
          $guestId: String!,
          $checkIn: String!,
          $checkOut: String!,
          $guests: Int!,
          $message: String!,
          $basePrice: Float!,
          $cleaningPrice: Float,
          $taxPrice: Float,
          $currency: String!,
          $discount: Float,
          $discountType: String,
          $guestServiceFee: Float,
          $hostServiceFee: Float,
          $total: Float!,
          $bookingType: String,
          $paymentType: Int!,
          $cancellationPolicy: Int!,
          $specialPricing: String,
          $isSpecialPriceAssigned: Boolean,
          $isSpecialPriceAverage: Float,
          $dayDifference: Float,
          $taxRate: Float,
          $checkInStart: String,
          $checkInEnd: String,
          $hostServiceFeeType: String,
          $hostServiceFeeValue: Float,
          $threadId: Int,
          $visitors: Int,
          $visitorsPrice: Float,
          $pets: Int,
          $petPrice: Float,
          $infants: Int,
          $infantPrice: Float,
          $additionalGuest: Int,
          $additionalPrice: Float, 
        ) {
          createReservation(
            listId: $listId,
            hostId: $hostId,
            guestId: $guestId,
            checkIn: $checkIn,
            checkOut: $checkOut,
            guests: $guests,
            message: $message,
            basePrice: $basePrice,
            cleaningPrice: $cleaningPrice,
            taxPrice: $taxPrice,
            currency: $currency,
            discount: $discount,
            discountType: $discountType,
            guestServiceFee: $guestServiceFee,
            hostServiceFee: $hostServiceFee,
            total: $total,
            bookingType: $bookingType,
            paymentType: $paymentType,
            cancellationPolicy: $cancellationPolicy,
            specialPricing: $specialPricing,
            isSpecialPriceAssigned: $isSpecialPriceAssigned,
            isSpecialPriceAverage: $isSpecialPriceAverage,
            dayDifference: $dayDifference,
            taxRate: $taxRate,
            checkInStart: $checkInStart,
            checkInEnd: $checkInEnd,
            hostServiceFeeType: $hostServiceFeeType,
            hostServiceFeeValue: $hostServiceFeeValue,
            threadId: $threadId,
            visitors: $visitors,
            visitorsPrice: $visitorsPrice,
            pets: $pets,
            petPrice: $petPrice,
            infants: $infants,
            infantPrice: $infantPrice,
            additionalGuest: $additionalGuest,
            additionalPrice: $additionalPrice,
     
          ) {
            id
            listId
            hostId
            guestId
            checkIn
            checkOut
            guests
            message
            basePrice
            cleaningPrice
            taxPrice
            currency
            discount
            discountType
            guestServiceFee
            hostServiceFee
            total
            confirmationCode
            createdAt
            status
            paymentMethodId
            cancellationPolicy
            isSpecialPriceAverage
            dayDifference
            taxRate
            checkInStart
            checkInEnd
            threadId
            hostServiceFeeType
            hostServiceFeeValue
            visitors
            visitorsPrice
            pets
            petPrice
            infants
            infantPrice
            additionalGuest
            additionalPrice
          }
        }
      `;

      // console.log('Executing mutation with variables:', {
      //   listId,
      //   hostId,
      //   guestId,
      //   checkIn,
      //   checkOut,
      //   guests,
      //   message,
      //   basePrice,
      //   cleaningPrice,
      //   taxPrice,
      //   currency,
      //   discount,
      //   discountType,
      //   guestServiceFee,
      //   hostServiceFee,
      //   total,
      //   bookingType,
      //   paymentType,
      //   cancellationPolicy,
      //   specialPricing,
      //   isSpecialPriceAssigned,
      //   isSpecialPriceAverage,
      //   dayDifference,
      //   taxRate,
      //   checkInStart,
      //   checkInEnd,
      //   hostServiceFeeType,
      //   hostServiceFeeValue,
      //   threadId,

      // });

      const { data } = await client.mutate({
        mutation,
        variables: {
          listId,
          hostId,
          guestId,
          checkIn,
          checkOut,
          guests,
          message,
          basePrice,
          cleaningPrice,
          taxPrice,
          currency,
          discount,
          discountType,
          guestServiceFee,
          hostServiceFee,
          total,
          bookingType,
          paymentType,
          cancellationPolicy,
          specialPricing,
          isSpecialPriceAssigned,
          isSpecialPriceAverage,
          dayDifference,
          taxRate,
          checkInStart,
          checkInEnd,
          hostServiceFeeType,
          hostServiceFeeValue,
          threadId,
          visitors,
          visitorsPrice,
          pets,
          petPrice,
          infants,
          infantPrice,
          additionalGuest,
          additionalPrice,
        }
      });

      console.log('Mutation result:', data);

      if (data?.createReservation) {
        const reservationId = data.createReservation.id;
        const amount = data?.createReservation?.total;
        const rates = getState().currency.rates;
        const baseCurrency = getState().currency.base;
        const currentCurrency = getState().currency.to || baseCurrency;
        const convertedAmount = convert(baseCurrency, rates, amount, currency, currentCurrency);
        // console.log(data, paymentType, "Create Reservation data");
        if (paymentType === 1) {
          const { status, errorMessage } = await sendPayment(reservationId, convertedAmount.toFixed(2), paymentCurrency, title);
          if (status === 200) {
            await dispatch({
              type: BOOKING_PAYMENT_SUCCESS,
              payload: { paymentLoading: false }
            });
          } else {
            showToaster({ messageId: status === 422 ? 'paypalError' : 'commonError', toasterType: 'error', requestContent: errorMessage });
            await dispatch({
              type: BOOKING_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });
          }
        } else {
          const { status, errorMessage } = await processRazorpayPayment(reservationId, amount, currency, title);
          if (status === 200) {
            await dispatch({
              type: BOOKING_PAYMENT_SUCCESS,
              payload: { paymentLoading: false }
            });
            return { status };
          } else {
            showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage });
            await dispatch({
              type: BOOKING_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });
            return { status, errorMessage };
          }
        }
      }
    } catch (error) {
      console.error('Error in makePayment:', error);
      await dispatch({
        type: BOOKING_PAYMENT_ERROR,
        payload: { error, paymentLoading: false }
      });
      return false;
    }

    return true;
  };
}

