import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Label, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// helpers
import history from '../../../core/history';
import messages from '../../../locale/messages';
import { removePayout } from '../../../actions/Payout/removePayoutAction';
import { setDefaultPayout } from '../../../actions/Payout/setDefaultPayout';
import showToaster from '../../../helpers/showToaster';

//Images
import tootipIcon from '../../../../public/SiteIcons/editInfoTipIcon.svg';
import delteIcon from '../../../../public/SiteIcons/deleteIcon.svg';
import addIcon from '../../../../public/SiteIcons/addListIcon.svg';

//style
import s from '../Payout.css';
import bt from '../../../components/commonStyle.css';
import { Field } from 'redux-form';

import CountryList from '../../CountryList/CountryList';

class PayoutList extends Component {
  static defaultProps = {
    payoutRemoveLoader: false,
    payoutDefaultLoader: false,
    payoutVerifyLoader: false,
    data: []
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currentPayout: {
        country: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        accountType: '',
        accountHolderName: '',
        accountNumber: '',
        ifscCode: '',
        gstNumber: '',
        panNumber: '',
        mobileNumber: '',
        confirmAccountNumber: ''
      }
    };
  }

  handleClick = () => {
    history.push('/user/addpayout');
  }

  handleAccDetailsConfirm = (payout, Id) => {
    this.setState({
      showModal: true,
      currentPayout: payout
    });
  }

  handleCloseFromModal = () => {
    this.setState({ showModal: false });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      currentPayout: {
        ...prevState.currentPayout,
        [name]: value,
        ...(name === 'accountNumber' && { confirmAccountNumber: value })
      }
    }));
  }


  validatePayoutDetails = () => {
    const { currentPayout } = this.state;
    const errors = {};
    if (!currentPayout.country) errors.country = "Country is required.";
    if (!currentPayout.address1) errors.address1 = "Address Line 1 is required.";
    if (!currentPayout.address2) errors.address2 = "Address Line 2 is required.";
    if (!currentPayout.city) errors.city = "City is required.";
    if (!currentPayout.state) errors.state = "State is required.";
    if (!currentPayout.zipcode) errors.zipcode = "Zip Code/Postal Code is required.";
    if (!currentPayout.accountType) errors.accountType = "Account Type is required.";
    if (!currentPayout.accountHolderName) errors.accountHolderName = "Account Holder Name is required.";
    if (!currentPayout.mobileNumber) errors.mobileNumber = "Mobile Number is required.";
    if (!currentPayout.accountNumber) errors.accountNumber = "Account Number is required.";
    if (!currentPayout.ifscCode) errors.ifscCode = "IFSC Code is required.";

    // Optionally, add more validation rules such as regex patterns for fields like mobileNumber

    return errors;
  }


  handleSubmit = async () => {

    const errors = this.validatePayoutDetails(); 
     if (Object.keys(errors).length > 0) {
      showToaster({
        messageId: 'commonError',
        toasterType: 'error',
        requestContent: errors.country || errors.address1 || errors.address2 || errors.city 
        || errors.state || errors.zipcode  || errors.accountType || errors.accountHolderName
        || errors.mobileNumber || errors.accountNumber  || errors.ifscCode,
      }); 
      return;
    }



    const { currentPayout } = this.state;
    const mutation = `
      mutation {
        updateSecPayment(
          userId: "${currentPayout.userId}",
          country: "${currentPayout.country}",
          address1: "${currentPayout.address1}",
          address2: "${currentPayout.address2}",
          city: "${currentPayout.city}",
          state: "${currentPayout.state}",
          zipcode: "${currentPayout.zipcode}",
          accountType: "${currentPayout.accountType}",
          accountHolderName: "${currentPayout.accountHolderName}",
          accountNumber: "${currentPayout.accountNumber}",
          ifscCode: "${currentPayout.ifscCode}",
          gstNumber: "${currentPayout.gstNumber}",
          panNumber: "${currentPayout.panNumber}",
          mobileNumber: "${currentPayout.mobileNumber}",
          confirmAccountNumber: "${currentPayout.confirmAccountNumber}"
        ) {
          status
          errorMessage
        }
      }
    `;

    try {
      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ query: mutation }),
        credentials: "include",
      });

      const result = await response.json();

      if (result.data && result.data.updateSecPayment) {
        const { status, errorMessage } = result.data.updateSecPayment;
        if (status === 'success') {
          showToaster({ messageId: 'Details', toasterType: 'success', requestContent: 'Your details has been updated successfully.' });
          // history.push('/user/payout');
          this.props.submit();
          this.setState({ showModal: false });

        } else {
          console.error("Error updating account details:", errorMessage);
          showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage || 'Error updating account details.' })
        }
      } else {
        console.error("Error:", result.errors || result.data.updateSecPayment.errorMessage);
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: result.errors ? result.errors[0].message : 'Error updating account details.' })
      }
    } catch (error) {
      console.error("Error updating account details:", error);
      showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: "Error updating account details ." });
    }
  };




  render() {
    const { data, removePayout, setDefaultPayout, currentAccountId, userId } = this.props;
    // console.log(data, "dddddddddddddd");

    const { formatMessage } = this.props.intl;
    const { payoutRemoveLoader, payoutDefaultLoader, payoutVerifyLoader } = this.props;
    const { showModal, currentPayout } = this.state;
    // console.log(currentPayout,"eeeeeeeeeeeeeeeee");


    return (
      <div className={cx('commonListingBg', 'payoutStepBg', 'bgBlack', 'noMarginBottom')}>
        <h3 className={s.titleText}>{formatMessage(messages.payoutMethod)}</h3>
        <p className={cx(s.textMuted, 'textWhite')}>
          <FormattedMessage {...messages.payoutTitleBlock1} />
        </p>
        <div className={cx('payoutTable', 'NewResponsiveTable', 'mobileTable')}>
          <table className={cx('table', s.noBorder)}>
            <thead>
              <tr className={cx(s.rowBorder, s.textTruncate)}>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Country</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Address Line 1</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Address Line 2</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>City</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>State</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Postal code</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Account Type</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Account Holder Name</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Mobile Number</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Account Number</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>IFSC Code</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>GST Number</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}>Pan Number</th>
                <th scope="col" className={cx(s.noBorder, 'textAlignRightRtl')}><FormattedMessage {...messages.editLabel} /></th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 && data.map((item, index) => (
                <tr className={cx(s.rowBorder, s.sectionTitleLight)} key={index}>
                  <td>{item.country}</td>
                  <td>{item.address1}</td>
                  <td>{item.address2}</td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{item.zipcode}</td>
                  <td>{item.accountType}</td>
                  <td>{item.accountHolderName}</td>
                  <td>{item.mobileNumber}</td>
                  <td>{item.accountNumber}</td>
                  <td>{item.ifscCode}</td>
                  <td>{item.gstNumber}</td>
                  <td>{item.panNumber}</td>
                  <td>
                    {showModal == false ?
                      <a onClick={() => this.handleAccDetailsConfirm(item, item.id)} href="javascript:void(0)">
                        <FormattedMessage {...messages.editLabel} />
                      </a>
                      :
                      <Modal show={showModal}>
                        <Modal.Header >
                          <Modal.Title>
                            <div className="text-center center-title">Edit Details</div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form className="form-horizontal px-2">
                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Country</label>
                                  <CountryList
                                    input={
                                      {
                                        value: currentPayout.country,
                                        onChange: this.handleChange,
                                        name: 'country'
                                      }
                                    }
                                    className={'form-control'}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Address Line 1</label>
                                  <input name="address1" type="text" className="form-control" value={currentPayout.address1} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Address Line 2</label>
                                  <input name="address2" type="text" className="form-control" value={currentPayout.address2} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>City</label>
                                  <input name="city" type="text" className="form-control" value={currentPayout.city} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>State</label>
                                  <input name="state" type="text" className="form-control" value={currentPayout.state} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Zip Code/ Postal code</label>
                                  <input name="zipcode" type="text" className="form-control" value={currentPayout.zipcode} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>


                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Account Type</label>
                                  <select name="accountType" className="form-control" value={currentPayout.accountType} onChange={this.handleChange} required>
                                    <option className="form-control" value="Saving">Saving</option>
                                    <option className="form-control" value="Current">Current</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Account Holder Name</label>
                                  <input name="accountHolderName" type="text" className="form-control" value={currentPayout.accountHolderName} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Mobile Number</label>
                                  <input name="mobileNumber" type="text" className="form-control" value={currentPayout.mobileNumber} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Account Number</label>
                                  <input name="accountNumber" type="text" className="form-control" value={currentPayout.accountNumber} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>IFSC Code</label>
                                  <input name="ifscCode" type="text" className="form-control" value={currentPayout.ifscCode} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>GST Number</label>
                                  <input name="gstNumber" type="text" className="form-control" value={currentPayout.gstNumber} onChange={this.handleChange} />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="col-sm-12">
                                <div className="d-flex flex-column">
                                  <label>Pan Number</label>
                                  <input name="panNumber" type="text" className="form-control" value={currentPayout.panNumber} onChange={this.handleChange} />
                                </div>
                              </div>
                            </div>
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={this.handleCloseFromModal}>
                            <FormattedMessage {...messages.close} />
                          </Button>
                          <Button variant="success" className='btn btn-success' onClick={this.handleSubmit}>
                            <FormattedMessage {...messages.submit} />
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


      </div>
    );
  }
}

const mapState = (state) => ({
  payoutRemoveLoader: state.loader.payoutRemove,
  payoutDefaultLoader: state.loader.payoutDefault,
  payoutVerifyLoader: state.loader.payoutVerify,
  userId: state.account.data.userId
});

const mapDispatch = {
  removePayout,
  setDefaultPayout
  // verifyPayout
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PayoutList)));
