import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Component
import CurrencyConverter from '../../CurrencyConverter';

// Locale
import messages from '../../../locale/messages';
import Faq from '../../../../public/SiteIcons/question.svg'

import s from './Payment.css';

class PaymentDetails extends Component {
  static propTypes = {
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    tax: PropTypes.number,
    currency: PropTypes.string.isRequired,
    dayDifference: PropTypes.number.isRequired,
    discount: PropTypes.number,
    discountType: PropTypes.string,
    priceForDays: PropTypes.number.isRequired,
    serviceFees: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    formatMessage: PropTypes.any,
    bookingSpecialPricing: PropTypes.array,
    isSpecialPriceAssigned: PropTypes.bool,

    houseRules: PropTypes.arrayOf(
      PropTypes.shape({
        listsettings: PropTypes.shape({
          itemName: PropTypes.string.isRequired,
        }),
      })
    ),
    additionalPrice: PropTypes.number,
    visitorsPrice: PropTypes.number,
    petPrice: PropTypes.number,
    infantPrice: PropTypes.number,
    GuestLimitOnProperty: PropTypes.number,
    infants: PropTypes.number,
    pets: PropTypes.number,
    visitors: PropTypes.number,
    guests: PropTypes.number,

  };

  static defaultProps = {
    bookingSpecialPricing: [],
    isSpecialPriceAssigned: false,
    houseRules: [],
    additionalPrice: 0,
    visitorsPrice: 0,
    petPrice: 0,
    infantPrice: 0,
    GuestLimitOnProperty: 0,
    infants: 0,
    pets: 0,
    visitors: 0,
  };

  state = {
    subTotal: 0,
    allTotal: 0,
    taxPer: 0,
    taxAmount:0,
  };


  logFormValues = () => {
    const {
      additionalPrice,
      visitorsPrice,
      petPrice,
      infantPrice,
      GuestLimitOnProperty,
      infants,
      pets,
      visitors,
      guests,
      dayDifference,
      basePrice,
      cleaningPrice,
    } = this.props;

    let tax = 0;
    let allTotal = 0;
    let taxAmount = 0;
    let additionalGuest = 0;


    let subTotal = basePrice + cleaningPrice;
    if(GuestLimitOnProperty > 0){
      additionalGuest = Number(guests) - Number(GuestLimitOnProperty);
    }

  
    if(additionalGuest > 0 && additionalPrice > 0){
      subTotal += (additionalGuest * additionalPrice);
    }
    if(visitorsPrice > 0 && visitors > 0){
      subTotal += (visitors * visitorsPrice);
    }
    if(petPrice > 0 && pets > 0){
      subTotal += (pets * petPrice);
    }
    if(infantPrice > 0 && infants > 0){
      subTotal += (infants * infantPrice);
    }
    tax = 18;
    if(subTotal <= 7500){
      tax = 12;
    }
    taxAmount = ((tax / 100) * (subTotal/ dayDifference));
    allTotal =  taxAmount + subTotal;
    this.setState({ subTotal: subTotal, allTotal: allTotal, taxPer: tax, taxAmount: taxAmount });  

    console.log(subTotal, allTotal, "Total Values");
  }

  componentDidMount() {
    this.logFormValues();
  }




  render() {
    const { cleaningPrice, tax, currency, dayDifference, isAverage } = this.props;
    const { priceForDays, serviceFees, discount, discountType, total, isSpecialPriceAssigned } = this.props;
    const { additionalPrice, visitorsPrice, petPrice, infantPrice, GuestLimitOnProperty, infants, pets, visitors, houseRules, guests } = this.props;
    const { formatMessage } = this.props.intl;

    const house = houseRules.map(rule => rule.houseRulesId);
    const { allTotal, subTotal, taxPer, taxAmount } = this.state;

    let AdditionalPerson = 0;

    if(GuestLimitOnProperty > 0){
     AdditionalPerson = (Number(guests) - Number(GuestLimitOnProperty))
    }


    function LinkWithTooltip({ id, children, href, tooltip }) {
      return (
        <OverlayTrigger
          overlay={<Tooltip className={s.tooltip} id={id}>{tooltip}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {children}
        </OverlayTrigger>
      );
    }

    return (
      <div>
        <h3 className={cx(s.pricingTitle, 'rtlBookText')}><FormattedMessage {...messages.priceDetails} /></h3>
        <div className={cx(s.grid, 'textWhite')}>
          <div>
            <div className={s.specialPriceIcon}>
              {
                isSpecialPriceAssigned &&
                <span className={s.iconSection}>
                  <img src={Faq} className={cx(s.faqImage, 'faqRtl')} />
                </span>

              }
              <div className={cx(s.tltip, s.relativeSection, 'relativeSectionRTL')}>
                <FormattedMessage {...messages.averagePricePerNight} />
              </div>
            </div>
            <span className={cx(s.specialPriceText, 'directionLtrTextRight')}>

              <CurrencyConverter
                amount={isAverage}
                from={currency}
              />
              {' x'} {dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}

            </span>
          </div>
          <div>
            <CurrencyConverter
              amount={priceForDays}
              from={currency}
            />
          </div>
        </div>
        {cleaningPrice > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div><FormattedMessage {...messages.cleaningFee} /></div>
          <div>
            <CurrencyConverter
              amount={cleaningPrice}
              from={currency}
            />
          </div>
        </div>
        }
        {serviceFees > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div><FormattedMessage {...messages.serviceFee} /></div>
          <div>
            <CurrencyConverter
              amount={serviceFees}
              from={currency}
            />
          </div>
        </div>
        }
        
        {discount > 0 && <div className={cx(s.grid, 'textWhite', s.discountText)}>
          <div>{discountType}</div>
          <div>
            -  <CurrencyConverter
              amount={discount}
              from={currency}
            />
          </div>
        </div>
        }

        {(guests > GuestLimitOnProperty) && AdditionalPerson > 0 &&
          <div className={cx(s.grid, 'textWhite')}>
            <div>
              <CurrencyConverter
                amount={additionalPrice}
                from={currency}
              />{" "}
              x {dayDifference} nights ( { AdditionalPerson} Additional Guest )</div>
            <div>
              <CurrencyConverter
                amount={(AdditionalPerson * additionalPrice) * dayDifference}
                from={currency}
              />
            </div>
          </div>
        }

        {house.includes("243") && visitors > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div>
            <CurrencyConverter
              amount={visitorsPrice}
              from={currency}
            />
            {" "}
            ( {visitors} Visitor )

          </div>
          <div>
            <CurrencyConverter
              amount={(visitorsPrice * visitors)}
              from={currency}
            />
          </div>
        </div>
        }

        {!house.includes("50") && pets > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div>
            <CurrencyConverter
              amount={petPrice}
              from={currency}
            />
            {" "}
            x {dayDifference} nights ( { pets } Pet ) </div>
          <div >
            <CurrencyConverter
              amount={(petPrice * pets) * dayDifference}
              from={currency}
            />
          </div>
        </div>
        }

        {!house.includes("48") && infants > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div>
            <CurrencyConverter
              amount={infantPrice}
              from={currency}
            />
            {" "}
            x {dayDifference} nights ( {infants} Infant ) </div>
          <div>
            <CurrencyConverter
              amount={(infantPrice * infants) * dayDifference}
              from={currency}
            />
          </div>
        </div>
        }

        {subTotal > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div>Sub Total</div>
          <div>
            <CurrencyConverter
              amount={subTotal}
              from={currency}
            />
          </div>
        </div>
        }

        {taxPer > 0 && <div className={cx(s.grid, 'textWhite')}>
          <div><FormattedMessage {...messages.tax}  />{" "}{taxPer} %</div>
          <div>
            <CurrencyConverter
              amount={taxAmount}
              from={currency}
            />
          </div>
        </div>
        }

        <div className={cx(s.grid, s.totalValue, 'textWhite')}>
          <div><FormattedMessage {...messages.total} /></div>
          <div> <CurrencyConverter
            amount={allTotal}
            from={currency}
          /></div>
        </div>
      </div>
    );
  }
}



const selector = formValueSelector('PaymentForm'); // <-- same as form name

const mapState = (state) => ({
  dayDifference: selector(state, 'dayDifference'),
  priceForDays: selector(state, 'priceForDays'),
  discount: selector(state, 'discount'),
  discountType: selector(state, 'discountType'),
  tax: selector(state, 'taxPrice'),
  serviceFees: selector(state, 'guestServiceFee'),
  total: selector(state, 'totalValue'),
  isSpecialPriceAssigned: selector(state, 'isSpecialPriceAssigned'),
  isAverage: selector(state, 'isSpecialPriceAverage')
});

const mapDispatch = {
};


export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PaymentDetails)));
