import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';
import ModalForm from '../ReservationManagement/ModalForm';
import HostServiceFee from './HostServiceFee';

import { decode } from '../../../helpers/queryEncryption';
import messages from '../../../locale/messages';

import bt from '../../../components/commonStyle.css';
import s from './ViewReservation.css';

class ViewReservation extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            id: PropTypes.number.isRequired,
            listId: PropTypes.number.isRequired,
            hostId: PropTypes.string.isRequired,
            guestId: PropTypes.string.isRequired,
            checkIn: PropTypes.string.isRequired,
            checkOut: PropTypes.string.isRequired,
            guestServiceFee: PropTypes.number.isRequired,
            hostServiceFee: PropTypes.number.isRequired,
            taxPrice: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            reservationState: PropTypes.string.isRequired,
            listData: PropTypes.shape({
                title: PropTypes.string.isRequired
            }),
            hostData: PropTypes.shape({
                profileId: PropTypes.number.isRequired,
                firstName: PropTypes.string.isRequired
            }),
            hostPayout: PropTypes.shape({
                id: PropTypes.number.isRequired,
                payEmail: PropTypes.string.isRequired
            }),
            hostTransaction: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }),
            guestData: PropTypes.shape({
                profileId: PropTypes.number.isRequired,
                firstName: PropTypes.string.isRequired
            }),
            transaction: PropTypes.shape({
                payerEmail: PropTypes.string.isRequired,
                paymentType: PropTypes.string.isRequired,
                total: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired
            }),
            refundStatus: PropTypes.shape({
                id: PropTypes.number.isRequired,
                receiverEmail: PropTypes.string.isRequired,
                total: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired
            }),
            cancellationDetails: PropTypes.shape({
                refundToGuest: PropTypes.number.isRequired,
                payoutToHost: PropTypes.number.isRequired,
                total: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired,
                guestServiceFee: PropTypes.number.isRequired,
                hostServiceFee: PropTypes.number.isRequired,
                cancellationPolicy: PropTypes.string,
                cancelledBy: PropTypes.string,
                isTaxRefunded: PropTypes.bool.isRequired,
            }),
        }),
        viewReceiptAdmin: PropTypes.any.isRequired,
    };
    static defaultProps = {
        data: [],
        secData: null,
        id: null
    };

    constructor(props) {
        super(props);
    
        this.state = {
            secData: null,
            error: null
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        const { completed, loading } = nextProps;
        const { refetch } = this.props;
        if (completed && !loading) {
            refetch();
        }
    }

    async componentDidMount() {
		const { data } = this.props;
        let userId = data.hostId;
	  
		  try {	  
		  const query = `
		  query {
			getSecPayment(userId: "${userId}") {
			  status
			  errorMessage
			  result {
				id
				userId
				accountType
				accountNumber
				confirmAccountNumber
				ifscCode
				accountHolderName
				gstNumber
				panNumber
				mobileNumber
				country
				address1   
				address2
				city
				state    
				zipcode
			  }
			}
		  }
		  `;
	
		  const response = await fetch("/graphql", {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			  Accept: "application/json",
			},
			body: JSON.stringify({ query }),
			credentials: "include",
		  });
	
		  const result = await response.json();
		  if (result && result?.data && result?.data?.getSecPayment) {
			this.setState({
			  secData: result?.data?.getSecPayment?.result,
			});
		  }
		} catch (error) {
		  this.setState({
			error: error.message
		  });
		}
	  }
    render() {
        const { type, data, data: { hostId, listData, cancellationDetails, transaction } } = this.props;
        const { secData, error } = this.state;
        let subTotal, taxAmount = 0, amountPaytoGuest = 0, amountPaytoHost = 0, guestFee = 0, hostFee = 0, today = moment();
        let nextDay = moment(data.checkIn).add(1, 'days'), dayDifference = nextDay.diff(today, 'days'), booktype, reservestate;
        let Guestname, Hostname, reservationStatus, bookingType, taxRate, url, titleMessageKey, taxStatus;
        const gobackcss = { padding: '10px' };
        let deduction = 0, deductionType = 'STD';
        taxAmount = data?.taxPrice;
        if (data) subTotal = data?.total + data?.guestServiceFee;
        console.log(data, "User Data");


        if (cancellationDetails) {
            amountPaytoGuest = cancellationDetails?.refundToGuest;
            amountPaytoHost = cancellationDetails?.payoutToHost;
            guestFee = cancellationDetails?.guestServiceFee;
            hostFee = cancellationDetails?.hostServiceFee;
        } else if (data) {
            amountPaytoHost = Number(data?.total) - Number(data?.hostServiceFee);
            guestFee = data?.guestServiceFee;
            hostFee = data?.hostServiceFee;
            if(secData != null && (secData?.gstNumber || secData?.panNumber)){
                deduction = ((amountPaytoHost*1)/100);
                amountPaytoHost = (amountPaytoHost - deduction);
                if(secData?.gstNumber) {
                    deductionType = 'GST';
                } else if(secData?.panNumber) {
                    deductionType = 'PAN';
                }
            } else {
                deduction = ((amountPaytoHost*5)/100);
                amountPaytoHost = (amountPaytoHost - deduction);
            }
            amountPaytoHost = amountPaytoHost - Number(data?.taxPrice);
        }

        if (data && (data?.reservationState == 'expired' || data?.reservationState == 'declined')) {
            guestFee = 0;
            hostFee = 0
        }

        if (data) reservestate = data.reservationState;

        if (listData) booktype = data.bookingType;

        if (data?.guestData) Guestname = data?.guestData?.firstName + " " + data?.guestData?.lastName;

        if (data?.hostData) Hostname = data?.hostData?.firstName + " " + data?.hostData?.lastName;

        if (reservestate) reservationStatus = reservestate.charAt(0).toUpperCase() + reservestate.slice(1);

        if (booktype) bookingType = booktype.charAt(0).toUpperCase() + booktype.slice(1);

        taxRate = data && data.taxRate && data.taxRate > 0 ? data.taxRate : 0
        url = type === 'reservation' ? '/siteadmin/reservations' : '/siteadmin/payout';
        titleMessageKey = type === 'reservation' ? 'reservationDetails' : 'payoutDetails';

        if (taxAmount > 0 && data?.hostTransaction?.id !== undefined && (cancellationDetails?.isTaxRefunded === false || data?.reservationState === 'completed')) {
            taxStatus = <FormattedMessage {...messages.taxPaidOutToHost} />
        } else if (taxAmount > 0 && data?.refundStatus?.id !== undefined && cancellationDetails?.isTaxRefunded === true) {
            taxStatus = <FormattedMessage {...messages.taxRefundToGuest} />
        }

        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <ModalForm />
                <>
                    <h1 className={s.headerTitle}><FormattedMessage {...messages[titleMessageKey]} /></h1>
                    <div className={cx(s.space4, bt.textAlignRight, 'textAlignLeftRtl')}>
                        <Link to={url} className={cx(bt.btnPrimaryBorder, bt.btnLarge, bt.noTextDecoration, 'bgBlack')}>
                            <FormattedMessage {...messages.goBack} />
                        </Link>
                    </div>
                    <div className={s.profileViewMain}>
                        {
                            data?.id && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.reservationId} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  {data?.id}  </span>
                            </div>
                        }
                        {
                            data?.confirmationCode && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}> <FormattedMessage {...messages.confirmationCode} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  {data?.confirmationCode}  </span>
                            </div>
                        }
                        {
                            reservationStatus && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.reservationStatus} />   </span>
                                <span className={cx(s.profileViewMainContent)}> {reservationStatus}  </span>
                            </div>
                        }
                        {
                            data?.cancellationDetails && data?.cancellationDetails.cancellationPolicy && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.cancellationPolicy} />   </span>
                                <span className={cx(s.profileViewMainContent)}> {data?.cancellationDetails?.cancellationPolicy}  </span>
                            </div>
                        }
                        {
                            data?.cancellationDetails && data?.cancellationDetails.cancelledBy && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.cancelledLabel} />   </span>
                                <span className={cx(s.profileViewMainContent, s.capitalize)}> {data?.cancellationDetails?.cancelledBy}  </span>
                            </div>
                        }
                        {
                            data?.listData && data?.listData.id && data?.listData?.title && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.titleLabel} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <a href={"/rooms/" + data?.listData?.id} target="_blank"> {data?.listTitle ? data.listTitle : data.listData.title}  </a>  </span>
                            </div>
                        }
                        {
                            data?.checkIn && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.checkIn} />   </span>
                                <span className={cx(s.profileViewMainContent)}> {moment(data?.checkIn).format("Do MMMM YYYY")}  </span>
                            </div>
                        }
                        {
                            data?.checkOut && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.checkOut} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  {moment(data?.checkOut).format("Do MMMM YYYY")}  </span>
                            </div>
                        }
                        {
                            bookingType && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.bookingType} />  </span>
                                <span className={cx(s.profileViewMainContent)}>  {bookingType}  </span>
                            </div>
                        }
                        {
                            data && (subTotal == 0 || subTotal > 0) && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.amountPaid} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <CurrencyConverter amount={subTotal} from={data.currency} />  </span>
                            </div>
                        }
                        <div className={s.profileViewInner}>
                            <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.guestServiceFee} />   </span>
                            <span className={cx(s.profileViewMainContent)}>  <CurrencyConverter amount={guestFee} from={data.currency} />  </span>
                        </div>
                        {
                            data && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.hostServiceFeeType} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <HostServiceFee
                                    hostId={data.hostId}
                                    checkIn={data.checkIn}
                                    id={data.id}
                                    hostPayout={data.hostPayout}
                                    amount={data.total}
                                    currency={data.currency}
                                    hostTransaction={data.hostTransaction}
                                    reservationState={data.reservationState}
                                    cancelData={data.cancellationDetails}
                                    hostServiceFee={hostFee}
                                /> </span>
                            </div>
                        }
                        {
                            data && (taxAmount == 0 || taxAmount > 0) && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.tax} />   </span>
                                <span className={cx(s.profileViewMainContent)}>
                                    <CurrencyConverter amount={taxAmount} from={data.currency} />
                                    {taxStatus ? <span>{' '}({taxStatus})</span> : ''}
                                </span>
                            </div>
                        }
                        {
                            data && deductionType == 'GST' && (deduction == 0 || deduction > 0) && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}> GST TCS – U/S 52 of GST for GST Number - {secData?.gstNumber}</span>
                                <span className={cx(s.profileViewMainContent)}>
                                    <CurrencyConverter amount={deduction} from={data.currency} />
                                </span>
                            </div>
                        }
                        {
                            data && deductionType == 'PAN' && (deduction == 0 || deduction > 0) && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>   Tax With holding for India Income for Pan Number - {secData?.panNumber}   </span>
                                <span className={cx(s.profileViewMainContent)}>
                                    <CurrencyConverter amount={deduction} from={data.currency} />
                                </span>
                            </div>
                        }
                        {
                            data && deductionType == 'STD' && (deduction == 0 || deduction > 0) && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  Tax With holding for India Income   </span>
                                <span className={cx(s.profileViewMainContent)}>
                                    <CurrencyConverter amount={deduction} from={data.currency} />
                                </span>
                            </div>
                        }
                        {
                            data?.guestData && data?.guestData?.profileId && Guestname && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.guestName} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <a href={"/users/show/" + data?.guestData?.profileId} target="_blank"> {Guestname}   </a>  </span>
                            </div>
                        }
                        {
                            data?.guestData && data?.guestData?.phoneNumber && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.guestPhoneNumber} />    </span>
                                <span className={cx(s.profileViewMainContent)}> {decode(data?.guestData?.phoneNumber)}  </span>
                            </div>
                        }
                        {
                            data?.guestUser && data?.guestUser?.email && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}> <FormattedMessage {...messages.guestEmail} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  {data?.guestUser?.email}  </span>
                            </div>
                        }
                        {
                            data?.hostData && data?.hostData?.profileId && Hostname && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.hostName} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <a href={"/users/show/" + data?.hostData?.profileId} target="_blank"> {Hostname}   </a> </span>
                            </div>
                        }
                        {
                            data?.hostData && data.hostData?.phoneNumber && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}> <FormattedMessage {...messages.hostPhoneNumber} />   </span>
                                <span className={cx(s.profileViewMainContent)}> {decode(data?.hostData?.phoneNumber)} </span>
                            </div>
                        }
                        {
                            data?.hostUser && data?.hostUser?.email && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.hostEmail} /> </span>
                                <span className={cx(s.profileViewMainContent)}> {data?.hostUser?.email} </span>
                            </div>
                        }
                        {
                            data?.cancellationDetails && data?.cancellationDetails?.createdAt && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.cancelDate} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  {moment(data?.cancellationDetails?.createdAt).format("Do MMMM YYYY")}  </span>
                            </div>
                        }
                        {
                            data && cancellationDetails && (cancellationDetails.refundToGuest == 0 || cancellationDetails.refundToGuest > 0) && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.refundAmount} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <CurrencyConverter amount={cancellationDetails?.refundToGuest}
                                    from={data.currency} />  </span>
                            </div>
                        }
                        {
                            data && !cancellationDetails && (data?.reservationState == 'expired' || data?.reservationState == 'declined') && <div className={s.profileViewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.refundAmount} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <CurrencyConverter amount={subTotal}
                                    from={data.currency} />  </span>
                            </div>
                        }
                        {
                            data && (data?.reservationState == 'approved' || data?.reservationState == 'completed' || data?.reservationState == 'cancelled') && <div className={s.lastviewInner}>
                                <span className={cx(s.labelTextNew, s.profileViewlabel)}>  <FormattedMessage {...messages.payoutLabel} />   </span>
                                <span className={cx(s.profileViewMainContent)}>  <CurrencyConverter amount={amountPaytoHost > 0 ? amountPaytoHost : 0}
                                    from={data.currency} />
                                </span>
                            </div>
                        }
                    </div>
                </>
            </div>
        );
    }

}
const mapState = (state) => ({
    completed: state.reservation.completed,
    loading: state.reservation.loading,
    // userId: state.data.hostId,
});
const mapDispatch = {
};
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ViewReservation)));