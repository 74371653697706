import { gql } from 'react-apollo';
import { destroy } from 'redux-form';
import history from '../../core/history';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../constants';
import showToaster from '../../helpers/showToaster';

export function addPayout(
  userId, 
  country,   
  address1, 
  address2,  
  city,  
  state, 
  zipcode,  
  accountType,  
  mobileNumber,
  accountHolderName,   
  accountNumber, 
  confirmAccountNumber,
  ifscCode, 
  gstNumber,  
  panNumber,   


) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: ADD_PAYOUT_START,
      payload: {
        payoutLoading: true,
      },
    });

    try {
      const mutation = gql`
        mutation createSecPayment(
          $userId: String!,
          $country: String!,
          $address1: String,    
          $address2: String,                      
          $city: String!,
          $state: String!,       
          $zipcode: String!,
          $accountType: String!
          $mobileNumber: String!, 
          $accountHolderName: String!,   
          $accountNumber: String!,       
          $confirmAccountNumber: String!,
          $ifscCode: String!,             
          $gstNumber: String,       
          $panNumber: String   

            
        
        ) {
          createSecPayment(
            userId: $userId,
            country: $country,
            address1: $address1,     
            address2: $address2,        
            city: $city,
            state: $state,
            zipcode: $zipcode,
            accountType: $accountType
            mobileNumber: $mobileNumber,   
            accountHolderName: $accountHolderName,
            accountNumber: $accountNumber,       
            confirmAccountNumber: $confirmAccountNumber,     
            ifscCode: $ifscCode, 
            gstNumber: $gstNumber,         
            panNumber: $panNumber, 
          
             
        
           
          ) {
            status
            errorMessage
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          userId,
          country,
          address1,   
          address2,
          city,
          state,     
          zipcode,
          accountType,
          mobileNumber, 
          accountHolderName,   
          accountNumber,       
          confirmAccountNumber,
          ifscCode,             
          gstNumber,
          panNumber,
               
        },
      });

      if (data && data.createSecPayment.status === '200') {
        dispatch({
          type: ADD_PAYOUT_SUCCESS,
          payload: {
            payoutLoading: false,
          },
        });
        showToaster({ messageId: 'addDetails', toasterType: 'success', requestContent: 'Your details has been added successfully.' });
        history.push('/user/payout');
     
        await dispatch(destroy('PayoutForm'));
    
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data.createSecPayment.errorMessage });
        dispatch({
          type: ADD_PAYOUT_ERROR,
          payload: {
            errorMessage: data.createSecPayment.errorMessage,
            payoutLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR,
        payload: {
          error,
          payoutLoading: false,
        },
      });
    }

    return true;
};
}