// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { FormattedMessage, injectIntl } from 'react-intl';
// import { Field, reduxForm } from 'redux-form';
// import { Button, FormGroup, FormControl } from 'react-bootstrap';
// import cx from 'classnames';
// import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import s from '../Payout.css';
// import bt from '../../../components/commonStyle.css';
// import validate from './validate';
// import messages from '../../../locale/messages';
// import submit from './submit';
// import { connect } from 'react-redux';

// class BankForm extends Component {
//     static propTypes = {
//         handleSubmit: PropTypes.func.isRequired,
//         formatMessage: PropTypes.func,
//         previousData: PropTypes.object, // Assuming previous data is passed as props
//         dispatch: PropTypes.func.isRequired,
//     };

//     renderField = ({ input, label, type, meta: { touched, error, dirty } }) => {
//         const { formatMessage } = this.props.intl;
//         return (
//             <FormGroup className={bt.space3}>
//                 <label className={cx(bt.commonLabelText, 'textWhite', 'responsiveTextAlignRtl')}>{label}</label>
//                 <FormControl {...input} componentClass="input" className={cx(bt.commonControlInput, s.formControlInput)} />
//                 {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
//             </FormGroup>
//         );
//     }

//     renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
//         const { formatMessage } = this.props.intl;
//         return (
//             <FormGroup className={bt.space3}>
//                 <label className={cx(bt.commonLabelText, 'textWhite', 'responsiveTextAlignRtl')}>{label}</label>
//                 <FormControl {...input} componentClass="select" className={cx(bt.commonControlInput, s.formControlInput)}>
//                     {children}
//                 </FormControl>
//                 {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
//             </FormGroup>
//         );
//     }

//     handleFormSubmit = (formData) => {
//         const { previousData } = this.props;

//         // Combine form data with previous data
//         const combinedData = {
//             ...previousData,
//             ...formData,
//         };

//         // Dispatch an action to handle the combined data
//         submit(combinedData);
//         console.log(combinedData,"3333333333333");
//     }

//     render() {
//         const { error, handleSubmit, previousPage } = this.props;
//         const { formatMessage } = this.props.intl;

//         return (
//             <div className={cx('inputFocusColor', 'commonListingBg', 'noMarginBottom')}>
//                 <form onSubmit={handleSubmit(this.handleFormSubmit)}>
//                     <h3 className={s.titleText}>
//                         {formatMessage(messages.bankDetails)}
//                     </h3>
//                     <Field name="accountType" component={this.renderSelectField} label="Account Type">
//                         <option value="">{formatMessage(messages.selectAccount)} </option>
//                         <option value="current">{formatMessage(messages.currentAccount)} </option>
//                         <option value="savings">{formatMessage(messages.savingsAccount)} </option>
//                     </Field>
//                     <Field name="accountHolderName" component={this.renderField} label={formatMessage(messages.accountHolderName)} />
//                     <Field name="accountNumber" component={this.renderField} label={formatMessage(messages.accountNumber)} />
//                     <Field name="confirmAccountNumber" component={this.renderField} label={formatMessage(messages.confirmAccountNumber)} />
//                     <Field name="ifscCode" component={this.renderField} label={formatMessage(messages.ifscCode)} />
//                     <h3 className={s.titleText}>Tax Details</h3>
//                     <Field name="gstNumber" component={this.renderField} label={formatMessage(messages.gstNumber)} />
//                     <Field name="panNumber" component={this.renderField} label={formatMessage(messages.panNumber)} />

//                     <p className='py-2'>
//                         Airhomestays is obligated to deduct 1% of the gross earnings of hosts who are residents of India,
//                         and remit these funds to the Indian tax authorities, per section 194-O of the Indian Tax Act.
//                         <br/>
//                         <br/>
//                         If the Indian host has not provided their PAN to Airhomestays, we are required to withhold tax at the
//                         rate of 5%. Please note, any funds that have already been withheld and remitted to tax authorities
//                         are unable to be returned by Airhomestays.
//                     </p>

//                     <div className={bt.textAlignRight}>
//                         <Button className={cx(bt.btnLarge, bt.btnPrimaryBorder, s.btnRight, 'spaceRight2AR', s.backBtn)} onClick={previousPage}>
//                             <FormattedMessage {...messages.back} />
//                         </Button>
//                         <Button className={cx(bt.btnPrimary, bt.btnLarge)} type="submit">
//                             <FormattedMessage {...messages.submit} />
//                         </Button>
//                     </div>
//                 </form>
//             </div>
//         );
//     }
// }

// BankForm = reduxForm({
//     form: 'PayoutBankForm',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     validate,
// })(BankForm);

// // Assuming previousData is part of the Redux state
// const mapStateToProps = (state) => ({
//     previousData: state.previousData, // replace with actual state slice
// });

// export default connect(mapStateToProps)(injectIntl(withStyles(s, bt)(BankForm)));




// BankForm.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Payout.css';
import bt from '../../../components/commonStyle.css';
import validate from './validate';
import messages from '../../../locale/messages';

class BankForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formatMessage: PropTypes.func,
    previousData: PropTypes.object, // Data from previous steps
    dispatch: PropTypes.func.isRequired,
  };

  renderField = ({ input, label, meta: { touched, error } }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={bt.space3}>
        <label className={cx(bt.commonLabelText, 'textWhite')}>{label}</label>
        <FormControl {...input} componentClass="input" className={cx(bt.commonControlInput, s.formControlInput)} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={bt.space3}>
        <label className={cx(bt.commonLabelText, 'textWhite')}>{label}</label>
        <FormControl {...input} componentClass="select" className={cx(bt.commonControlInput, s.formControlInput)}>
          {children}
        </FormControl>
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  handleFormSubmit = (formData) => {
    const normalizedData = {
      ...formData,
      gstNumber: formData.gstNumber || '',
      panNumber: formData.panNumber || '',
    };

    this.props.onSubmit(normalizedData);
    console.log(normalizedData);
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx('inputFocusColor', 'commonListingBg', 'noMarginBottom')}>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          <h3 className={s.titleText}>
            {formatMessage(messages.bankDetails)}
          </h3>
          <Field name="accountType" component={this.renderSelectField} label="Account Type">
            <option value="">{formatMessage(messages.selectAccount)} </option>
            <option value="Current">{formatMessage(messages.currentAccount)} </option>
            <option value="Saving">{formatMessage(messages.savingsAccount)} </option>
          </Field>
          <Field name="accountHolderName" component={this.renderField} label={formatMessage(messages.accountHolderName)} />
          <Field name="mobileNumber" component={this.renderField} label={formatMessage(messages.mobileNumberLabel)} />
          <Field name="accountNumber" component={this.renderField} label={formatMessage(messages.accountNumber)} />
          <Field name="confirmAccountNumber" component={this.renderField} label={formatMessage(messages.confirmAccountNumber)} />
          <Field name="ifscCode" component={this.renderField} label={formatMessage(messages.ifscCode)} />
          <h3 className={s.titleText}>Tax Details</h3>
          <Field name="gstNumber" component={this.renderField} label={formatMessage(messages.gstNumber)} />
          <Field name="panNumber" component={this.renderField} label={formatMessage(messages.panNumber)} />

          <p className='py-2'>
            Airhomestays is obligated to deduct 1% of the gross earnings of hosts who are residents of India,
            and remit these funds to the Indian tax authorities, per section 194-O of the Indian Tax Act.
            <br />
            <br />
            If the Indian host has not provided their PAN to Airhomestays, we are required to withhold tax at the
            rate of 5%. Please note, any funds that have already been withheld and remitted to tax authorities
            are unable to be returned by Airhomestays.
          </p>

          <div className={bt.textAlignRight}>
            <Button className={cx(bt.btnLarge, bt.btnPrimaryBorder, s.btnRight, 'spaceRight2AR', s.backBtn)} onClick={previousPage}>
              <FormattedMessage {...messages.back} />
            </Button>
            <Button className={cx(bt.btnPrimary, bt.btnLarge)} type="submit">
              <FormattedMessage {...messages.submit} />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

BankForm = reduxForm({
  form: 'PayoutBankForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(BankForm);

export default injectIntl(withStyles(s, bt)(BankForm));
