import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Field, reduxForm } from 'redux-form';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroup from 'react-bootstrap/lib/InputGroup';

// Component
import updateStep3 from './updateStep3';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

import messages from '../../locale/messages';
import validateStep3 from './validateStep3';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';

import bt from '../../components/commonStyle.css';
import s from './ListPlaceStep1.css';
class Pricing extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any
  };



  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { valid } = this.props;
    console.log(this.props, this.state, "Testing Values");
    this.setState({ isDisabled: valid ? false : true })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid } = nextProps;
    this.setState({ isDisabled: valid ? false : true })
  }




  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          {...input}
          placeholder={label}
          type={type}
          className={className}
          maxLength={12}
        />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  } 
  

  renderFormControlTax = ({ input, label, type, meta: { touched, error }, className, prefixLabel }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <InputGroup>
          <FormControl {...input} placeholder={label} type={type} className={className} maxLength={12} />
          <InputGroup.Addon className={s.prefixIcon}>{prefixLabel}</InputGroup.Addon>
        </InputGroup>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className} >
          {children}
        </FormControl>
      </div>
    )
  }

  // -----------------------------


  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step, availableCurrencies, houseRules, personCapacity } = this.props;
    const { isDisabled } = this.state;

    console.log(personCapacity, houseRules, "House Rules");

    const { formatMessage } = this.props.intl;

    return (
      <div className={s.grid}>
        <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.tabPricing)}
        />
        <form onSubmit={handleSubmit}>
          <div className={cx(s.landingMainContent, 'disCountAddon')}>
            <FormGroup className={s.formGroup}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.currency} />
              </ControlLabel>

              <Field name="currency" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')} >
                {
                  availableCurrencies?.length > 0 && availableCurrencies.map((currency, key) => {
                    if (currency.isEnable === true) {
                      return <option key={key} value={currency.symbol}>{currency.symbol}</option>
                    }
                  })
                }
              </Field>
            </FormGroup>

              <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage id="AdditionalPerson" defaultMessage="Number of Guests Allowed on Base price" />
              </ControlLabel>
              <Field
                name="guestBasePrice"
                component={this.renderFormControlSelect}
                className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              >
                <option value="" >Select</option>
                {[...Array(personCapacity)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </Field>
            </FormGroup>

            
            <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.basePrice} />
              </ControlLabel>
              <Field
                name="basePrice"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.basePriceLabel)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
            </FormGroup>
            <div className={s.tipCommonCss}>
              <img src={toolTipIcon} />
              <span className={cx(s.commonTipCsss, 'textWhite')}><FormattedMessage {...messages.basePriceTip} /></span>
            </div>

            <FormGroup className={cx(s.formGroup, s.space4)}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.cleaningPrice} />
              </ControlLabel>
              <Field
                name="cleaningPrice"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.cleaningPrice)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
            </FormGroup>


            {/* <FormGroup className={cx(s.formGroup, s.space4)}>
                <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                  <FormattedMessage {...messages.tax} />
                </ControlLabel>
                <Field
                  name="tax"
                  type="text"
                  component={this.renderFormControlTax}
                  label={formatMessage(messages.tax)}
                  className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  prefixLabel={'%'}
                />
              </FormGroup> */}

            {/* ----------------------------------------------------------- */}


          

            <FormGroup className={cx(s.formGroup, s.space4)}>
              <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage id="GuestPrice" defaultMessage="Extra guests fee (per person)" />
              </ControlLabel>
              <Field
                name="additionalPrice"
                type="text"
                component={this.renderFormControl}
                label="Extra guests fee (per person)"
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
            </FormGroup>

            {houseRules.includes(243) && (
              <>
                <FormGroup className={cx(s.formGroup, s.space4)}>
                  <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                    <FormattedMessage id="visitorsLimit" defaultMessage="Maximum Number of visitor allowed" />
                  </ControlLabel>
                  <Field
                    name="visitorsLimit"
                    component={this.renderFormControlSelect}
                    className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  >
                    <option value="" >Select</option>
                    {[...Array(50)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </Field>
                </FormGroup>

                <FormGroup className={cx(s.formGroup, s.space4)}>
                  <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                    <FormattedMessage id="visitorsPrice" defaultMessage="Visitor fee per visit" />
                  </ControlLabel>
                  <Field
                    name="visitorsPrice"
                    type="text"
                    component={this.renderFormControl}
                    label="Enter visitor fee"
                    className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  />
                </FormGroup>
              </>
            )}

            {!houseRules.includes(50) && (
              <>
                <FormGroup className={cx(s.formGroup, s.space4)}>
                  <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                    <FormattedMessage id="petLimit" defaultMessage="Maximum Number of pets allowed " />
                  </ControlLabel>
                  <Field
                    name="petLimit"
                    component={this.renderFormControlSelect}
                    className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  >
                    <option value="" >Select</option>
                    {[...Array(10)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </Field>
                </FormGroup>

                <FormGroup className={cx(s.formGroup, s.space4)}>
                  <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                    <FormattedMessage id="petPrice" defaultMessage="Pet fee per night" />
                  </ControlLabel>
                  <Field
                    name="petPrice"
                    type="text"
                    component={this.renderFormControl}
                    label="Enter pet fee"
                    className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  />
                </FormGroup>
              </>
            )}

            {!houseRules.includes(48) && (
              <>
                <FormGroup className={cx(s.formGroup, s.space4)}>
                  <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                    <FormattedMessage id="infantLimit" defaultMessage="Maximum number of infants allowed:( less than 3 years) " />
                  </ControlLabel>
                  <Field
                    name="infantLimit"
                    component={this.renderFormControlSelect}
                    className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  >
                    <option value="">Select</option>
                    {[...Array(10)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </Field>
                </FormGroup>

                <FormGroup className={cx(s.formGroup, s.space4)}>
                  <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                    <FormattedMessage id="infantPrice" defaultMessage="Infant fee per night" />
                  </ControlLabel>
                  <Field
                    name="infantPrice"
                    type="text"
                    component={this.renderFormControl}
                    label="Enter infant fee"
                    className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  />
                </FormGroup>
              </>
            )}


          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={"discount"}
            previousPagePath={"advance-notice"}
            formPage={formPage}
            step={step}
            isDisabled={isDisabled}
          />
        </form>
      </div>
    );
  }
}

Pricing = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
  // onSubmit: updateStep4

})(Pricing);

const selector = formValueSelector('ListPlaceStep3'); // <-- same as form name
const selectorStep1 = formValueSelector('ListPlaceStep1'); // <-- same as form name

const mapState = (state) => {
  // console.log('Redux State:', state);
  return {
    listingFields: state.listingFields.data,
    availableCurrencies: state.currency.availableCurrencies,
    base: state.currency.base,
    mapUpdateLoading: state.location.mapUpdateLoading,
    houseRules: selector(state, 'houseRules'),
    personCapacity: selectorStep1(state, 'personCapacity')
  };
};

const mapDispatch = {change,};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Pricing)));