import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Payout.css';

// Component
import PayoutList from './PayoutList';
import EmptyList from './PayoutList/EmptyList';
import Loader from '../Loader';

class Payout extends Component {
  static defaultProps = {
    loading: true,
    data: []
  }

  constructor(props) {
    super(props);

    this.state = {
      initialLoad: true,
      data: [],
      loading: true,
      error: null
    };
  }

  async fetchData() {
    const { userId } = this.props;

    this.setState({
      initialLoad: false
    });

    try {
      const query = `
      query {
        getSecPayment(userId: "${userId}") {
          status
          errorMessage
          result {
            id
            userId
            accountType
            accountNumber
            confirmAccountNumber
            ifscCode
            accountHolderName
            gstNumber
            panNumber
            mobileNumber
            country
            address1   
            address2
            city
            state    
            zipcode
          }
        }
      }
      `;

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ query }),
        credentials: "include",
      });

      const result = await response.json();
      // console.log(result, "");

      if (result.data && result.data.getSecPayment) {
        this.setState({
          data: Array.isArray(result.data.getSecPayment.result) ? result.data.getSecPayment.result : result.data.getSecPayment.result == null ? []: [result.data.getSecPayment.result ],
          loading: false
        });
      } else {
        this.setState({
          loading: false,
          data: [],
          error: result.errors || 'Unknown error'
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message
      });
    }
  }

   componentDidMount() {
    this.fetchData();
  }

  render() {
    const { currentAccountId } = this.props;
    const { initialLoad, data, loading, error } = this.state;
    // console.log(data, "xxxxxxxxxxxxxxxxxx");
    // console.log(data.length, "xxxxxxxxxxxxxxxxxx");

    if (loading && initialLoad) {
      return <Loader type={"text"} />;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    if (data.length > 0) {
      return <PayoutList data={data} currentAccountId={currentAccountId} submit={this.fetchData.bind(this)} />;
    } else {
      return <EmptyList />;
    }
  }
}

const mapState = (state) => ({
  userId: state.account.data.userId
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Payout)));
