import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
// Redux Form
import { Field, reduxForm, formValueSelector } from 'redux-form';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';
// Component
import DateRange from '../DateRange';
import BillDetails from './BillDetails';
import BookingButton from './BookingButton';
// Redux action
import { bookingProcess } from '../../../actions/booking/bookingProcess';
// Locale
import messages from '../../../locale/messages';
import bt from '../../../components/commonStyle.css';
import s from './Calendar.css';
import { calculateTotalPrice } from '../../../helpers/calculateTotalPrice';



class BookingForm extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    personCapacity: PropTypes.number.isRequired,
    country: PropTypes.string,
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    tax: PropTypes.number,
    currency: PropTypes.string,
    monthlyDiscount: PropTypes.number,
    weeklyDiscount: PropTypes.number,
    minNight: PropTypes.number,
    maxNight: PropTypes.number,
    maxDaysNotice: PropTypes.string,
    loading: PropTypes.bool,
    availability: PropTypes.bool,
    maximumStay: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    blockedDates: PropTypes.array,
    isHost: PropTypes.bool.isRequired,
    guests: PropTypes.number,
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    bookingType: PropTypes.string.isRequired,
    bookingLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    account: PropTypes.shape({
      userBanStatus: PropTypes.number,
    }),
    guestBasePrice: PropTypes.number,
    additionalPrice: PropTypes.number,
    visitorsLimit: PropTypes.number,
    visitorsPrice: PropTypes.number,
    petLimit: PropTypes.number,
    petPrice: PropTypes.number,
    infantLimit: PropTypes.number,
    infantPrice: PropTypes.number,
    house: PropTypes.array.isRequired,
  };
  static defaultProps = {
    blockedDates: [],
    availability: true,
    maximumStay: false,
    minimumStay: false,
    startDate: null,
    endDate: null,
    guests: 1,
    personCapacity: 0,
    country: '',
    house: [],
    guestBasePrice: 0,
    additionalPrice: 0,
    visitorsPrice: 0,
    petPrice: 0,
    infantPrice: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      personCapacityData: [],
      calculatedvisitorsPrice: 0,
      calculatedpetPrice: 0,
      calculatedinfantPrice: 0,
      calculateadditionalPrice: 0,

    };
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({ personCapacityData: listingFields.personCapacity });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({ personCapacityData: listingFields.personCapacity });
    }
  }

  // renderFormControlSelect({ input, label, meta: { touched, error }, children, className }) {
  //   return (
  //     <div>
  //       <FormControl componentClass="select" {...input} className={className} >
  //         {children}
  //       </FormControl>
  //     </div>
  //   );
  // }


  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { guestBasePrice, additionalPrice } = this.props;

    const handleChange = (event) => {
      const value = parseInt(event.target.value, 10);
      if (input.name === 'guests' && value > guestBasePrice) {
        const calculateadditionalPrice = (value - guestBasePrice) * additionalPrice;
        this.setState({ calculateadditionalPrice });
      }
      if (input.name === 'guests' && value <= guestBasePrice) {
        this.setState({ calculateadditionalPrice: 0 });
      }
      input.onChange(event);
    };

    return (
      <div>
        <FormControl
          componentClass="select"
          {...input}
          className={className}
          onChange={handleChange}
        >
          {children}
        </FormControl>
      </div>
    );
  };


  renderGuests(personCapacity) {
    const { personCapacityData } = this.state;
    const rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(<option key={i} value={i}>{i} {i > 1 ? personCapacityData && personCapacityData.length > 0 && personCapacityData[0] && personCapacityData[0].otherItemName : personCapacityData && personCapacityData.length > 0 && personCapacityData[0] && personCapacityData[0].itemName}</option>);
    }
    return rows;
  }

  handleLimitChange = (value, priceType) => {
    let calculatedPrice = value * this.props[priceType];
    this.setState({ [`calculated${priceType}`]: calculatedPrice });
  };

  // handleAdditionalChange = () => {
  //   const { guestBasePrice, additionalPrice } = this.props;
  //   if (value > guestBasePrice) {
  //     const calculatedPrice = (value - guestBasePrice) * additionalPrice;
  //     this.setState({ calculateadditionalPrice: calculatedPrice });
  //   } else {
  //     this.setState({ calculateadditionalPrice: 0 });
  //   }
  // };


  // Example: Call logFormValues when the component mounts
  logFormValues = () => {

    const { guests, selectedVisitors, selectedPet, selectedInfant } = this.props;
    // console.log(guests, selectedVisitors, selectedPet, selectedInfant, "4444444444444444444444444");


    if (guests > 0) {
      const { guestBasePrice, additionalPrice } = this.props;
      if (guests > guestBasePrice) {
        const data = (guests - guestBasePrice) * additionalPrice;
        this.setState({ calculateadditionalPrice: data });
      }
      if (guests <= guestBasePrice) {
        this.setState({ calculateadditionalPrice: 0 });
      }
    }

    if (selectedVisitors > 0) {
      const { visitorsPrice } = this.props;
      const data = selectedVisitors * visitorsPrice;
      this.setState({ calculatedvisitorsPrice: data });
    }

    if (selectedPet > 0) {
      const { petPrice } = this.props;
      const data = selectedPet * petPrice;
      this.setState({ calculatedpetPrice: data });
    }

    if (selectedInfant > 0) {
      const { infantPrice } = this.props;
      const data = selectedInfant * infantPrice;
      this.setState({ calculatedinfantPrice: data });
    }

  }

  componentDidMount() {
    this.logFormValues();
  }



  render() {
    const { formatMessage } = this.props.intl;
    const { id, personCapacity, basePrice, cleaningPrice, tax, currency, isHost, bookingType, taxRate } = this.props;
    const { monthlyDiscount, weeklyDiscount, minNight, maxNight, maxDaysNotice } = this.props;
    const { isLoading, availability, maximumStay, guests, startDate, endDate, account, blockedDates, minimumStay, country } = this.props;
    const { bookingProcess, serviceFees, base, rates, bookingLoading, initialValues } = this.props;
    const { guestBasePrice, additionalPrice, visitorsLimit, visitorsPrice, petLimit, petPrice, infantLimit, infantPrice, house } = this.props
    const isDateChosen = startDate != null && endDate != null || false;
    let userBanStatusValue;
    if (account) {
      const { account: { userBanStatus } } = this.props;
      userBanStatusValue = userBanStatus;
    }
    const houseRulesIds = house.map(rule => rule.houseRulesId);
    const subTotal = basePrice + cleaningPrice;
    // console.log(basePrice, cleaningPrice, subTotal, "Data");

    const { calculatedvisitorsPrice, calculatedpetPrice, calculatedinfantPrice, calculateadditionalPrice } = this.state;
    
    console.log(houseRulesIds, "House Rule ids");


    // console.log(additionalPrice, "additionalPrice", guestBasePrice, "guestBasePrice", visitorsLimit, "visitorsLimit",
    //   visitorsPrice, "visitorsPrice", petLimit, "petLimit", petPrice, "petPrice",
    //   infantLimit, "infantLimit", infantPrice, "infantPrice", "all Dataaaaaaaaaaaaaaaaaa");

    // console.log("calculateadditionalPrice", calculateadditionalPrice, "calculatedvisitorsPrice", calculatedvisitorsPrice, "calculatedpetPrice", calculatedpetPrice, "calculatedinfantPrice", calculatedinfantPrice);


    return (
      <Form>
        <FormGroup className={s.noMargin}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <label className={cx(s.guestText, 'guestTextRTL')}>
                <FormattedMessage {...messages.dates} />
              </label>
              <span className={cx('viewListingDate')}>
                <DateRange
                  listId={id}
                  minimumNights={minNight}
                  maximumNights={maxNight}
                  blockedDates={blockedDates}
                  formName={'BookingForm'}
                  maxDaysNotice={maxDaysNotice}
                  country={country}
                />
              </span>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <ControlLabel className={cx(s.guestText, 'guestTextRTL')}><FormattedMessage {...messages.howManyGuest} /></ControlLabel>
              <Field
                name="guests"
                component={this.renderFormControlSelect}
                className={cx(s.formControlSelect, bt.commonControlSelect, 'viewGuestCount', s.guestFormControlSelectw)}
                handleAdditionalChange
              >
                {this.renderGuests(personCapacity)}
              </Field>
            </Col>


            {houseRulesIds.includes("243") && visitorsLimit > 0 && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <ControlLabel className={cx(s.guestText, 'guestTextRTL')}>Visitors</ControlLabel>
                <Field
                  name="selectedVisitors"
                  component={this.renderFormControlSelect}
                  className={cx(s.formControlSelect, bt.commonControlSelect, 'viewGuestCount', s.guestFormControlSelectw)}
                  onChange={(event, newValue) => this.handleLimitChange(newValue, 'visitorsPrice')}
                >
                  <option key={0} value={0}>Select Visitors</option>
                  {[...Array(visitorsLimit)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>{`${i + 1} Visitors`}</option>
                  ))}
                </Field>
              </Col>
            )}

            {!houseRulesIds.includes("50") && petLimit > 0 && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <ControlLabel className={cx(s.guestText, 'guestTextRTL')}>Pets </ControlLabel>

                <Field
                  name="selectedPet"
                  component={this.renderFormControlSelect}
                  className={cx(s.formControlSelect, bt.commonControlSelect, 'viewGuestCount', s.guestFormControlSelectw)}
                  onChange={(event, newValue) => this.handleLimitChange(newValue, 'petPrice')}
                >
                  <option key={0} value={0}>Select Pets</option>
                  {[...Array(petLimit)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>{`${i + 1} Pets`}</option>
                  ))}
                </Field>
              </Col>
            )}

            {!houseRulesIds.includes("48") && infantLimit > 0 && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <ControlLabel className={cx(s.guestText, 'guestTextRTL')}>Infants : (less than 3 years)</ControlLabel>
                <Field
                  name="selectedInfant"
                  component={this.renderFormControlSelect}
                  className={cx(s.formControlSelect, bt.commonControlSelect, 'viewGuestCount', s.guestFormControlSelect)}
                  onChange={(event, newValue) => this.handleLimitChange(newValue, 'infantPrice')}
                >
                  <option key={0} value={0}>Select Infants</option>
                  {[...Array(infantLimit)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>{`${i + 1} Infants`}</option>
                  ))}
                </Field>
              </Col>
            )}
          </Row>
        </FormGroup>
        <FormGroup>
          {
            !isLoading && maximumStay && isDateChosen && !userBanStatusValue && <div className={cx(s.bookItMessage, s.spaceTop3)}>
              <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                <FormattedMessage {...messages.maximumStay} /> {maxNight} {maxNight > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
              </p>
            </div>
          }
          {
            !isLoading && minimumStay && isDateChosen && !userBanStatusValue && <div
              className={cx(s.bookItMessage, s.spaceTop3)}
            >
              <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                <FormattedMessage {...messages.minimumNightStay} />  {minNight} {minNight > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
              </p>
            </div>
          }

          {
            !isLoading && !availability && isDateChosen && !userBanStatusValue && <div className={cx(s.bookItMessage, s.spaceTop3)}>
              <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                <FormattedMessage {...messages.hostErrorMessage2} />
              </p>
            </div>
          }
        </FormGroup>
        {
          !isLoading && !maximumStay && !minimumStay && availability && isDateChosen && !userBanStatusValue && <BillDetails
            basePrice={basePrice}
            cleaningPrice={cleaningPrice}
            tax={tax}
            currency={currency}
            monthlyDiscount={monthlyDiscount}
            weeklyDiscount={weeklyDiscount}
            startDate={startDate}
            endDate={endDate}
            serviceFees={serviceFees}
            base={base}
            rates={rates}
            taxRate={taxRate}
            calculatedvisitorsPrice={calculatedvisitorsPrice}
            calculatedpetPrice={calculatedpetPrice}
            calculatedinfantPrice={calculatedinfantPrice}
            calculateadditionalPrice={calculateadditionalPrice}
            guestBasePrice={guestBasePrice}
            guests={!isNaN(guests) ? guests : 1}
            visitors={!isNaN(this.props.selectedVisitors) ? this.props.selectedVisitors : 0}   // Pass selectedVisitors as a prop
            pets={!isNaN(this.props.selectedPet) ? this.props.selectedPet : 0}            // Pass selectedPet as a prop
            infants={!isNaN(this.props.selectedInfant) ? this.props.selectedInfant : 0}       // Pass selectedInfant as a prop  
            additionalPrice={additionalPrice}
            visitorsPrice={visitorsPrice}
            petPrice={petPrice}
            infantPrice={infantPrice}
            house={houseRulesIds}
          />
        }
        <BookingButton
          listId={id}
          startDate={startDate}
          endDate={endDate}
          guests={!isNaN(guests) ? guests : 1}
          visitors={!isNaN(this.props.selectedVisitors) ? this.props.selectedVisitors : 0}   // Pass selectedVisitors as a prop
          pets={!isNaN(this.props.selectedPet) ? this.props.selectedPet : 0}            // Pass selectedPet as a prop
          infants={!isNaN(this.props.selectedInfant) ? this.props.selectedInfant : 0}       // Pass selectedInfant as a prop
          bookingProcess={bookingProcess}
          availability={availability}
          isDateChosen={isDateChosen}
          userBanStatus={userBanStatusValue}
          basePrice={basePrice}
          isHost={isHost}
          bookingType={bookingType}
          bookButton={!isLoading && !maximumStay && !minimumStay && availability && isDateChosen && !userBanStatusValue}
          bookingLoading={bookingLoading}
          maximumStay={maximumStay}
          taxRate={taxRate}
          minimumStay={minimumStay}

        />
      </Form>
    );
  }
}
BookingForm = reduxForm({
  form: 'BookingForm', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(BookingForm);
// Decorate with connect to read form values
const selector = formValueSelector('BookingForm'); // <-- same as form name

const mapState = state => {
  // console.log(state, "State data");
  return {

    isLoading: state.viewListing.isLoading,
    availability: state.viewListing.availability,
    maximumStay: state.viewListing.maximumStay,
    minimumStay: state.viewListing.minimumStay,
    startDate: selector(state, 'startDate'),
    endDate: selector(state, 'endDate'),
    guests: Number(selector(state, 'guests')),
    account: state.account.data,
    serviceFees: state.book.serviceFees,
    base: state.currency.base,
    rates: state.currency.rates,
    bookingLoading: state.book.bookingLoading,
    listingFields: state.listingFields.data,
    selectedVisitors: Number(selector(state, 'selectedVisitors')),
    selectedPet: Number(selector(state, 'selectedPet')),
    selectedInfant: Number(selector(state, 'selectedInfant')),
  }
};
const mapDispatch = {
  bookingProcess,
};
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(BookingForm)));
