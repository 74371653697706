import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import { connect } from 'react-redux';
import moment from 'moment';
import Confirm from 'react-confirm-bootstrap';
import { graphql, gql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import bt from "../../../components/commonStyle.css";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormControl } from 'react-bootstrap';

import CustomPagination from '../../CustomPagination';
import { removeListing } from '../../../actions/siteadmin/ListingManagement/removeListing';
import { addListToRecommended, removeListFromRecommended } from '../../../actions/siteadmin/ListingManagement/manageRecommend';
import messages from '../../../locale/messages';
import listingsQuery from './listingsQuery.graphql';
import ExportImage from '../../../../public/adminIcons/export.png';
import s from './ListingManagement.css';
import { Modal, Button } from 'react-bootstrap';

import showToaster from '../../../helpers/showToaster';
// import bankDetails from './bankDetail.graphql';
// import { Field  } from 'redux-form';

// import {Button} from 'react-bootstrap/Button';
// import {Modal} from 'react-bootstrap/Modal';

import CountryList from '../../CountryList/CountryList';



class ListingManagement extends React.Component {
  static propTypes = {
    getAllListings: PropTypes.array,
    addListToRecommended: PropTypes.func.isRequired,
    removeListFromRecommended: PropTypes.func.isRequired,

    data: PropTypes.object.isRequired,
  };



  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      showModal: false,
      showForm: false,
      accountDetails: {
        country: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        accountType: '',
        accountHolderName: '',
        accountNumber: '',
        ifscCode: '',
        gstNumber: '',
        panNumber: '',
        mobileNumber: '',
        confirmAccountNumber: ''
      }

    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      accountDetails: {
        ...prevState.accountDetails,
        [name]: value,
        ...(name === 'accountNumber' && { confirmAccountNumber: value })
      }
    }));
  };

  paginationData = (currentPage) => {
    const { getAllListings: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  };

  handleClick = (searchList) => {
    const { getAllListings: { refetch } } = this.props;
    let variables = { currentPage: 1, searchList };
    this.setState({ currentPage: 1 });
    refetch(variables);
  };

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450),
    });
  };

  deleteListing = async (id, type) => {
    const { removeListing } = this.props;
    const { getAllListings: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    await removeListing(id, type);
    this.setState({ currentPage: 1 });
    await refetch(variables);
  };

  validatePayoutDetails = () => {
    const { accountDetails } = this.state;
    const errors = {};
    if (!accountDetails.country) errors.country = "Country is required.";
    if (!accountDetails.address1) errors.address1 = "Address Line 1 is required.";
    if (!accountDetails.address2) errors.address2 = "Address Line 2 is required.";
    if (!accountDetails.city) errors.city = "City is required.";
    if (!accountDetails.state) errors.state = "State is required.";
    if (!accountDetails.zipcode) errors.zipcode = "Zip Code/Postal Code is required.";
    if (!accountDetails.accountType) errors.accountType = "Account Type is required.";
    if (!accountDetails.accountHolderName) errors.accountHolderName = "Account Holder Name is required.";
    if (!accountDetails.mobileNumber) errors.mobileNumber = "Mobile Number is required.";
    if (!accountDetails.accountNumber) errors.accountNumber = "Account Number is required.";
    if (!accountDetails.ifscCode) errors.ifscCode = "IFSC Code is required.";
    return errors;
  }

  handleSubmit = async () => {

    // const errors = this.validatePayoutDetails();
    // if (Object.keys(errors).length > 0) {
    //   showToaster({
    //     messageId: 'commonError',
    //     toasterType: 'error',
    //     requestContent: errors.country || errors.address1 || errors.address2 || errors.city
    //       || errors.state || errors.zipcode || errors.accountType || errors.accountHolderName
    //       || errors.mobileNumber || errors.accountNumber || errors.ifscCode,
    //   });
    //   return;
    // }

    const { accountDetails, userId } = this.state;
    // console.log(accountDetails, "Account Details Update");
    const mutation = `
      mutation {
        updateSecPayment(
          userId: "${userId}",
          country: "${accountDetails?.country ? accountDetails?.country : ''}",
          address1: "${accountDetails?.address1 ? accountDetails?.address1 : ''}",
          address2: "${accountDetails?.address2 ? accountDetails.address2 : ''}",
          city: "${accountDetails?.city ? accountDetails.city : ''}",
          state: "${accountDetails?.state ? accountDetails.state : ''}",
          zipcode: "${accountDetails?.zipcode ? accountDetails.zipcode : ''}",
          accountType: "${accountDetails.accountType ? accountDetails.accountType : ''}",
          accountHolderName: "${accountDetails?.accountHolderName ? accountDetails.accountHolderName : ''}",
          accountNumber: "${accountDetails?.accountNumber ? accountDetails.accountNumber : ''}",
          ifscCode: "${accountDetails?.ifscCode ? accountDetails.ifscCode : ''}",
          gstNumber: "${accountDetails?.gstNumber ? accountDetails.gstNumber : ''}",
          panNumber: "${accountDetails?.panNumber ? accountDetails.panNumber : ''}",
          mobileNumber: "${accountDetails?.mobileNumber ? accountDetails.mobileNumber : ''}",
          confirmAccountNumber: "${accountDetails?.confirmAccountNumber? accountDetails.confirmAccountNumber : ''}"
        ) {
          status
          errorMessage
        }
      }
    `;

    try {
      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ query: mutation }),
        credentials: "include",
      });

      const result = await response.json();

      if (result.data && result.data.updateSecPayment) {
        const { status, errorMessage } = result.data.updateSecPayment;
        if (status === 'success') {
          showToaster({ messageId: 'AccountDetails', toasterType: 'success', requestContent: 'Details Updated successfully.' });
          this.setState({ showModal: false, showForm: false, accountDetails: accountDetails });
        } else {
          console.error("Error updating account details:", errorMessage);
          showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage || 'Error updating account details.' })
        }
      } else {
        console.error("Error:", result.errors || result.data.updateSecPayment.errorMessage);
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: result.errors ? result.errors[0].message : 'Error updating account details.' })
      }
    } catch (error) {
      console.error("Error updating account details:", error);
      showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: "Error updating account details ." });
    }
  };


  handleSubmitCreate = async () => {

    // const errors = this.validatePayoutDetails();
    // if (Object.keys(errors).length > 0) {
    //   showToaster({
    //     messageId: 'commonError',
    //     toasterType: 'error',
    //     requestContent: errors.country || errors.address1 || errors.address2 || errors.city
    //       || errors.state || errors.zipcode || errors.accountType || errors.accountHolderName
    //       || errors.mobileNumber || errors.accountNumber || errors.ifscCode,
    //   });
    //   return;
    // }

    const { accountDetails, userId } = this.state;
    // console.log(accountDetails, "Account Details Create");
    const mutation = `
      mutation {
        createSecPayment(
          userId: "${userId}",
          country: "${accountDetails?.country ? accountDetails?.country : ''}",
          address1: "${accountDetails?.address1 ? accountDetails?.address1 : ''}",
          address2: "${accountDetails?.address2 ? accountDetails.address2 : ''}",
          city: "${accountDetails?.city ? accountDetails.city : ''}",
          state: "${accountDetails?.state ? accountDetails.state : ''}",
          zipcode: "${accountDetails?.zipcode ? accountDetails.zipcode : ''}",
          accountType: "${accountDetails.accountType ? accountDetails.accountType : ''}",
          accountHolderName: "${accountDetails?.accountHolderName ? accountDetails.accountHolderName : ''}",
          accountNumber: "${accountDetails?.accountNumber ? accountDetails.accountNumber : ''}",
          ifscCode: "${accountDetails?.ifscCode ? accountDetails.ifscCode : ''}",
          gstNumber: "${accountDetails?.gstNumber ? accountDetails.gstNumber : ''}",
          panNumber: "${accountDetails?.panNumber ? accountDetails.panNumber : ''}",
          mobileNumber: "${accountDetails?.mobileNumber ? accountDetails.mobileNumber : ''}",
          confirmAccountNumber: "${accountDetails?.confirmAccountNumber? accountDetails.confirmAccountNumber : ''}"
        ) {
          status
          errorMessage
        }
      }
    `;

    try {
      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ query: mutation }),
        credentials: "include",
      });

      const result = await response.json();

      if (result.data && result.data.createSecPayment) {
        const { status, errorMessage } = result.data.createSecPayment;
        // console.log(status, errorMessage, result, "Create Sec Payment Result");
        if (status === "200") {
          showToaster({ messageId: 'AccountDetails', toasterType: 'success', requestContent: 'Details Updated successfully.' });
          this.setState({ showModal: false, showForm: false, accountDetails: accountDetails, addNew: false });
        } else {
          console.error("Error updating account details:", errorMessage);
          showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage || 'Error updating account details.' })
        }
      } else {
        console.error("Error:", result.errors || result.data.createSecPayment.errorMessage);
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: result.errors ? result.errors[0].message : 'Error updating account details.' })
      }
    } catch (error) {
      console.error("Error updating account details:", error);
      showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: "Error updating account details ." });
    }
  };




  handleAccDetailsConfirm = async (userId) => {

    try {
      const query = `
      query {
        getSecPayment(userId: "${userId}") {
          status
          errorMessage
          result {
            id
            userId
            country
            address1
            address2
            city
            state
            zipcode
            accountType
            accountHolderName
            accountNumber
            ifscCode
            gstNumber
            panNumber
            mobileNumber
            confirmAccountNumber
            createdAt
          }
        }
      }
    `;

      const response = await fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ query }),
        credentials: "include",
      });

      const result = await response.json();

      if (result.data && result.data.getSecPayment) {
        this.setState({
          showModal: true,
          accountDetails: result.data.getSecPayment.result,
          userId: userId,
          addNew: result.data.getSecPayment.result == null ? true : false,
        });
      } else {
        console.error("Error:", result.errors || result.data.getSecPayment.errorMessage);
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  };



  handleCloseFromModal = () => {
    this.setState({ showForm: false });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, accountDetails: null });
  };

  handleEditClick = () => {
    // console.log(this.state.accountDetails, this.state.addNew, "Edit Click Values");
    this.setState({ showForm: true });
  };

  render() {
    const { intl, removeListing, addListToRecommended, removeListFromRecommended } = this.props;
    const { getAllListings: { loading, getAllListings } } = this.props;

    // console.log(getAllListings,"33333333333333");
    const { currentPage, searchList, showModal, accountDetails, showForm } = this.state;
    const { formatMessage } = this.props.intl;
    let createPay = false;
    if(accountDetails === null) {
      createPay = true;
    }
    // console.log(createPay, accountDetails,"Render Account Details");
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.listingsManagement} /></h1>
          <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
            <div>
              <FormControl
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={this.handleSearchChange}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
            <div>
              {
                getAllListings && getAllListings.usersData.length > 0 && <a
                  href={`/export-admin-data?type=listings&keyword=${searchList}`}
                  className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}
                >
                  <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                  <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </a>
              }
            </div>
          </div>
          <div className={cx('table-responsive', 'listing-table', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin', 'topScrollbarTable')}>
            <Table className={cx("table", 'topScrollbarTableContent')}
              noDataText={formatMessage(messages.noRecordFound)}
              sortable={true}
              defaultSort={{ column: 'Id', direction: 'desc' }}
            >
              <Thead>
                <Th scope="col">{formatMessage(messages.idLabel)}</Th>
                <Th scope="col">{formatMessage(messages.adminTitleLabel)}</Th>
                <Th scope="col">{formatMessage(messages.hostNameLabel)}</Th>
                <Th scope="col">{formatMessage(messages.hostEMailLabel)}</Th>
                <Th scope="col">{formatMessage(messages.address)}</Th>
                <Th scope="col">{formatMessage(messages.city)}</Th>
                <Th scope="col">{formatMessage(messages.stateLabel)}</Th>
                <Th scope="col">{formatMessage(messages.country)}</Th>
                <Th scope="col">{formatMessage(messages.createdDate)}</Th>
                <Th scope="col">{formatMessage(messages.recommendLabel)}</Th>
                <Th scope="col">{formatMessage(messages.publishedLabel)}</Th>
                <Th scope="col">{formatMessage(messages.AccDetails)}</Th>
                <Th scope="col">{formatMessage(messages.ready)}</Th>
                <Th scope="col">{formatMessage(messages.editLabel)}</Th>
                <Th scope="col">{formatMessage(messages.viewLabel)}</Th>
                <Th scope="col">{formatMessage(messages.delete)}</Th>
              </Thead>
              {
                !loading && getAllListings?.usersData?.map((value, key) => {

                  let viewListing = "/rooms/" + value.id;
                  let editListing = '/become-a-host/' + value.id + '/home';
                  let isPublished = value.isPublished ? 'Yes' : 'No';
                  let isReady = value.isReady ? 'Yes' : 'No';
                  return (
                    <Tr key={key}>
                      <Td data-label={formatMessage(messages.idLabel)} column={formatMessage(messages.idLabel)} data={value.id} />
                      <Td data-label={formatMessage(messages.adminTitleLabel)} column={formatMessage(messages.adminTitleLabel)} data={value.title} />
                      <Td data-label={formatMessage(messages.hostNameLabel)} column={formatMessage(messages.hostNameLabel)} data={value.user.profile.firstName} />
                      <Td data-label={formatMessage(messages.hostEMailLabel)} column={formatMessage(messages.hostEMailLabel)} data={value.user.email} />
                      {
                        !value.buildingName && value.street && value.city && value.state && value.country && value.zipcode && <Td data-label={formatMessage(messages.address)} column={formatMessage(messages.address)} data={value.street + ', ' + value.city + ', ' + value.state + ', ' + value.country + ', ' + value.zipcode} />
                      }
                      {
                        value.buildingName && value.street && value.city && value.state && value.country && value.zipcode && <Td data-label={formatMessage(messages.address)} column={formatMessage(messages.address)} data={value.street + ', ' + value.buildingName + ', ' + value.city + ', ' + value.state + ', ' + value.country + ', ' + value.zipcode} />
                      }
                      <Td data-label={formatMessage(messages.city)} column={formatMessage(messages.city)} data={value.city} />
                      <Td data-label={formatMessage(messages.stateLabel)} column={formatMessage(messages.stateLabel)} data={value.state} />
                      <Td data-label={formatMessage(messages.country)} column={formatMessage(messages.country)} data={value.country} />
                      <Td data-label={formatMessage(messages.createdDate)} column={formatMessage(messages.createdDate)} data={moment(value.createdAt).format('MM/DD/YYYY')} />
                      {
                        value.recommend != null && <Td data-label={formatMessage(messages.recommendLabel)} column={formatMessage(messages.recommendLabel)}>
                          <a href="javascript:void(0)" onClick={() => removeListFromRecommended(value.id, currentPage, searchList)} >
                            <FormattedMessage {...messages.remove} />
                          </a>
                        </Td>
                      }
                      {
                        value.recommend == null && <Td data-label={formatMessage(messages.recommendLabel)} column={formatMessage(messages.recommendLabel)}>
                          <a href="javascript:void(0)" onClick={() => addListToRecommended(value.id, currentPage, searchList)} >
                            <FormattedMessage {...messages.setLabel} />
                          </a>
                        </Td>
                      }
                      <Td data-label={formatMessage(messages.publishedLabel)} column={formatMessage(messages.publishedLabel)}>
                        {isPublished}
                      </Td>

                      <Td data-label={formatMessage(messages.AccDetails)} column={formatMessage(messages.AccDetails)}>
                        <a onClick={() => this.handleAccDetailsConfirm(value.user.id)} href="javascript:void(0)"><FormattedMessage {...messages.viewLabel} /></a>
                      </Td>

                      <Td data-label={formatMessage(messages.ready)} column={formatMessage(messages.ready)}>
                        {isReady}
                      </Td>
                      <Td data-label={formatMessage(messages.editLabel)} column={formatMessage(messages.editLabel)}>
                        <a href={editListing} target="_blank" >
                          <FormattedMessage {...messages.editLabel} />
                        </a>
                      </Td>
                      <Td data-label={formatMessage(messages.viewLabel)} column={formatMessage(messages.viewLabel)}>
                        <a href={viewListing} target="_blank" >
                          <FormattedMessage {...messages.viewLabel} />
                        </a>
                      </Td>
                      <Td data-label={formatMessage(messages.delete)} column={formatMessage(messages.delete)}>
                        <Confirm
                          onConfirm={() => this.deleteListing(value.id, "admin")}
                          body={formatMessage(messages.areYouSureDeleteWishList)}
                          confirmText={formatMessage(messages.confirmDelete)}
                          cancelText={formatMessage(messages.cancel)}
                          title={formatMessage(messages.deletingListingTitle)}
                        >
                          <a href="javascript:void(0)"><FormattedMessage {...messages.delete} /></a>
                        </Confirm>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Table>
          </div>
          <div>
            {
              getAllListings && getAllListings.usersData && getAllListings.usersData.length > 0
              && <div>
                <CustomPagination
                  total={getAllListings.count}
                  currentPage={currentPage}
                  defaultCurrent={1}
                  defaultPageSize={10}
                  change={this.paginationData}
                  paginationLabel={formatMessage(messages.lists)}
                  isScroll
                />
              </div>
            }
          </div>
          {showModal == true &&
                          <div>
                            <Modal show={showModal}>
                              <Modal.Header>
                                <Modal.Title>
                                  <div className="text-center center-title">Account Details</div>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                { showForm == false && 
                                  <table className="table table-bordered table-striped text-center">
                                    <tbody>
                                      <tr>
                                        <th className="p-3">Country</th>
                                        <td className="p-3">{accountDetails?.country ? accountDetails.country : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Address Line 1</th>
                                        <td className="p-3">{accountDetails?.address1 ? accountDetails.address1 : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Address Line 2</th>
                                        <td className="p-3">{accountDetails?.address2 ? accountDetails.address2 : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">City</th>
                                        <td className="p-3">{accountDetails?.city ? accountDetails.city : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">State</th>
                                        <td className="p-3">{accountDetails?.state ? accountDetails.state : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Zipcode</th>
                                        <td className="p-3">{accountDetails?.zipcode ? accountDetails.zipcode : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Account Type</th>
                                        <td className="p-3">{accountDetails?.accountType ? accountDetails.accountType : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Account Holder Name</th>
                                        <td className="p-3">{accountDetails?.accountHolderName ? accountDetails.accountHolderName : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Mobile Number</th>
                                        <td className="p-3">{accountDetails?.mobileNumber ? accountDetails.mobileNumber : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Account Number</th>
                                        <td className="p-3">{accountDetails?.accountNumber ? accountDetails.accountNumber : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">IFSC Code</th>
                                        <td className="p-3">{accountDetails?.ifscCode ? accountDetails.ifscCode : '--'}</td>
                                      </tr>

                                      <tr>
                                        <th className="p-3">GST Number</th>
                                        <td className="p-3">{accountDetails?.gstNumber ? accountDetails.gstNumber : '--'}</td>
                                      </tr>
                                      <tr>
                                        <th className="p-3">Pan Number</th>
                                        <td className="p-3">{accountDetails?.panNumber ? accountDetails.panNumber : '--'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                }
                                {showForm == false ?
                                  (<></>) :
                                  <form className="form-horizontal px-2" >

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Country</label>
                                          <CountryList
                                            input={
                                              {
                                                value: this.state?.accountDetails?.country,
                                                onChange: this.handleChange,
                                                name: 'country'
                                              }
                                            }
                                            className={'form-control'}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Address Line 1</label>
                                          <input name="address1" type="text" className="form-control" value={this.state?.accountDetails?.address1} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Address Line 2</label>
                                          <input name="address2" type="text" className="form-control" value={this.state?.accountDetails?.address2} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>City</label>
                                          <input name="city" type="text" className="form-control" value={this.state?.accountDetails?.city} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>State</label>
                                          <input name="state" type="text" className="form-control" value={this.state?.accountDetails?.state} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Zip Code/ Postal code</label>
                                          <input name="zipcode" type="text" className="form-control" value={this.state?.accountDetails?.zipcode} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Account Type</label>
                                          <select name="accountType" className="form-control" value={this.state?.accountDetails?.accountType} onChange={this.handleChange} required>
                                            <option value="">Select Account Type</option>
                                            <option className="form-control" value="Saving">Saving</option>
                                            <option className="form-control" value="Current">Current</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Account Holder Name</label>
                                          <input name="accountHolderName" type="text" className="form-control" value={this.state?.accountDetails?.accountHolderName} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Mobile Number</label>
                                          <input name="mobileNumber" type="text" className="form-control" value={this.state?.accountDetails?.mobileNumber} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Account Number</label>
                                          <input name="accountNumber" type="text" className="form-control" value={this.state?.accountDetails?.accountNumber} onChange={this.handleChange} required />

                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>IFSC Code</label>
                                          <input name="ifscCode" type="text" className="form-control" value={this.state?.accountDetails?.ifscCode} onChange={this.handleChange} required />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>GST Number</label>
                                          <input name="gstNumber" type="text" className="form-control" value={this.state?.accountDetails?.gstNumber} onChange={this.handleChange} />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="col-sm-12">
                                        <div className="d-flex flex-column">
                                          <label>Pan Number</label>
                                          <input name="panNumber" type="text" className="form-control" value={this.state?.accountDetails?.panNumber} onChange={this.handleChange} />
                                        </div>
                                      </div>
                                    </div>
                                  </form>

                                }


                              </Modal.Body>
                              <Modal.Footer>

                                {showForm == true ?
                                  <>
                                    <Button variant="Success" className='btn-Success ml-2 p-2' onClick={this.handleCloseFromModal}>
                                      <FormattedMessage {...messages.close} />
                                    </Button>
                                    {this.state?.addNew == true ?
                                    <Button variant="Success" className='btn btn-success p-2' onClick={this.handleSubmitCreate}>
                                      <FormattedMessage {...messages.addNewLabel} />
                                    </Button>
                                    : 
                                    <Button variant="Success" className='btn btn-success p-2' onClick={this.handleSubmit}>
                                      <FormattedMessage {...messages.submit} />
                                    </Button>
                                    }
                                    </>
                                  :
                                  <Button variant="danger" className='btn-danger ml-2 p-2' onClick={this.handleCloseModal}>
                                    <FormattedMessage {...messages.close} />
                                  </Button>
                                }
                                {showForm == false ?
                                  <Button variant="Success" className='btn-Success mr-2 p-2' onClick={this.handleEditClick}>
                                    <FormattedMessage {...messages.editLabel} />
                                  </Button> : <></>
                                }

                              </Modal.Footer>
                            </Modal>
                          </div>
                        }
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
  removeListing,
  addListToRecommended,
  removeListFromRecommended
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(listingsQuery, {
    name: 'getAllListings',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only',
    }
  })
)(ListingManagement);
