import fetch from '../../fetch';
import { payment as config } from '../../../config';
import errorMessage from '../../../helpers/ErrorMessages/errorMessages_en';

export async function processRazorpayPayment(reservationId, amount, currency, description) {
    let totalAmt = (amount * 100);
    const orderResp = await fetch("/createOrder", {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ 'amount': totalAmt, 'currency': currency, 'receipt': reservationId }),
        credentials: 'include'
    });

    const { status, data } = await orderResp.json();
    
    if (status === 200) {
        const options = {
            key: config.razorpay.keyId,
            amount: data?.amount,
            currency: data?.currency,
            name: 'Airhomestays',
            order_id: data?.id,
            handler: async (response) => {
                const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;

                const verifyPayment = await fetch("/verifyPayment", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        razorpay_payment_id,
                        razorpay_order_id,
                        razorpay_signature,
                        'reservation_id': reservationId,
                        'total_amount':amount
                    }),
                    credentials: 'include'
                });

                const { status, redirect, errorMessage } = await verifyPayment.json();
                if (status == 200 && redirect) window.location = redirect;
            }
        }
        const rzp = new window.Razorpay(options);
        rzp.open();

        rzp.on('payment.failed', function (response){
            status = 400, errorMessage = response.error.code +' - '+ response.error.description;
            // return {
            //     status: 400,
            //     errorMessage: response.error.code +' - '+ response.error.description
            // }
        });
console.log(status, errorMessage);
        return {
            status,
            errorMessage
        }
    }
}
