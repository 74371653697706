// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { reduxForm } from 'redux-form';
// import { injectIntl } from 'react-intl';

// // Components
// import PayoutBillingDetails from './PayoutBillingDetails';
// import PayoutConfirm from './PayoutConfirm';
// import PayoutMethods from './PayoutMethods';
// import BankForm from './BankForm';
// import submit from './submit'
// ;
// class PayoutForm extends Component {
//   static propTypes = {
//     initialValues: PropTypes.object.isRequired
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       page: 1
//     };
//     this.nextPage = this.nextPage.bind(this)
//     this.previousPage = this.previousPage.bind(this)
//   }

//   nextPage() {
//     this.setState({ page: this.state.page + 1 })
//   }

//   previousPage() {
//     this.setState({ page: this.state.page - 1 })
//   }

//   render() {
//     const { page } = this.state;
//     // console.log(page,"99999999999999999999999999999999");
//     const { initialValues } = this.props;
//     // console.log(initialValues,"55555555555555555555");

//     return (
//       <div className={'payoutStepBg'}>
//         {
//           page === 1 && <PayoutBillingDetails
//             onSubmit={this.nextPage}
//             initialValues={initialValues}
//           />
//         }
//         {
//           page === 2 && <BankForm
//             onSubmit={submit}
//             initialValues={initialValues}
//             previousPage={this.previousPage}
//           />
//         }
//         {/* {
//           page === 2 && <PayoutMethods
//             previousPage={this.previousPage}
//             onSubmit={this.nextPage}
//             initialValues={initialValues}
//           />
//         }
//         {
//           page === 3 && <PayoutConfirm
//             previousPage={this.previousPage}
//             onSubmit={this.nextPage}
//             initialValues={initialValues}
//           />
//         } */}
//       </div>
//     );
//   }
// }

// PayoutForm = reduxForm({
//   form: 'PayoutForm', // a unique name for this form
//   destroyOnUnmount: true,
// })(PayoutForm);

// export default injectIntl(PayoutForm);
// PayoutForm.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PayoutBillingDetails from './PayoutBillingDetails';
import BankForm from './BankForm';
import submit from './submit';

class PayoutForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    previousData: PropTypes.object, // For data from previous steps
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      formData: {}, // To store data from each step
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  nextPage(formData) {
    this.setState((prevState) => ({
      page: prevState.page + 1,
      formData: { ...prevState.formData, ...formData },
    }));
  }

  previousPage() {
    this.setState((prevState) => ({ page: prevState.page - 1 }));
  }

  handleFormSubmit(formData) {
    const { formData: previousData } = this.state;
    const combinedData = { ...previousData, ...formData };
    this.props.handleSubmit((values, dispatch) => submit(combinedData, dispatch))();
    console.log(combinedData,"222222222222");
  }

  render() {
    const { page, formData } = this.state;
    const { initialValues } = this.props;

    return (
      <div className={'payoutStepBg'}>
        {page === 1 && (
          <PayoutBillingDetails
            onSubmit={this.nextPage}
            initialValues={initialValues}
          />
        )}
        {page === 2 && (
          <BankForm
            onSubmit={this.handleFormSubmit}
            previousPage={this.previousPage}
            previousData={formData}
          />
        )}
      </div>
    );
  }
}

PayoutForm = reduxForm({
  form: 'PayoutForm',
  destroyOnUnmount: false,
})(PayoutForm);

export default injectIntl(PayoutForm);
