// Redux Form
import { SubmissionError } from 'redux-form';

// Fetch request
import fetch from '../../core/fetch';

// Redux Action
import { getListingDataStep3 } from '../../actions/getListingDataStep3';
import { manageListingSteps } from '../../actions/manageListingSteps';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

// For Redirect
import history from '../../core/history';
// Locale
import messages from '../../locale/messages';

async function updateStep3(values, dispatch) {

  let weeklyDiscount = values?.weeklyDiscount || 0;
  let monthlyDiscount = values?.monthlyDiscount || 0;
  let cleaningPrice = values?.cleaningPrice || 0;
  let tax = values?.tax || 0;


  let id, houseRules, bookingNoticeTime, checkInStart, checkInEnd, maxDaysNotice, minNight, maxNight, basePrice, currency, blockedDates, bookingType, cancellationPolicy,
  guestBasePrice, additionalPrice, visitorsLimit, visitorsPrice, petLimit, petPrice, infantLimit, infantPrice;

  id = values?.id;
  houseRules = values?.houseRules;
  bookingNoticeTime = values?.bookingNoticeTime;
  checkInStart = values?.checkInStart;
  checkInEnd = values?.checkInEnd;
  maxDaysNotice = values?.maxDaysNotice;
  minNight = values?.minNight;
  maxNight = values?.maxNight;
  basePrice = values?.basePrice;
  currency = values?.currency;
  blockedDates = values?.blockedDates;
  bookingType = values?.bookingType;
  cancellationPolicy = values?.cancellationPolicy;
  // ---------------------------------------

  guestBasePrice = values?.guestBasePrice > 0 ? values?.guestBasePrice : 0;
  additionalPrice = values?.additionalPrice;
  visitorsLimit = values?.visitorsLimit;
  visitorsPrice = values?.visitorsPrice;
  petLimit = values?.petLimit;
  petPrice = values?.petPrice;
  infantLimit = values?.infantLimit;
  infantPrice = values?.infantPrice;

  let variables = Object.assign({}, {
    weeklyDiscount, monthlyDiscount, cleaningPrice, tax, id, houseRules, bookingNoticeTime,
    checkInStart, checkInEnd, maxDaysNotice, minNight, maxNight, basePrice, currency, blockedDates, bookingType, cancellationPolicy,
    guestBasePrice, additionalPrice, visitorsLimit, visitorsPrice, petLimit, petPrice, infantLimit, infantPrice
  });

  dispatch(setLoaderStart('updateListing'));
  
  const query = `query updateListingStep3(
    $id: Int,
    $houseRules: [Int],
    $bookingNoticeTime: String,
    $checkInStart: String,
    $checkInEnd: String,
    $maxDaysNotice: String,
    $minNight: Int,
    $maxNight: Int,
    $basePrice: Float,
    $cleaningPrice: Float,
    $tax: Float,
    $currency: String,
    $weeklyDiscount: Int,
    $monthlyDiscount: Int,
    $blockedDates: [String],
    $bookingType: String!,
    $cancellationPolicy: Int,
    $guestBasePrice: Int,
    $additionalPrice: Float,
    $visitorsLimit: Int, 
    $visitorsPrice: Float, 
    $petLimit: Int,
    $petPrice: Float,
    $infantLimit: Int,
    $infantPrice: Float
  ) {
    updateListingStep3 (
      id: $id,
      houseRules: $houseRules,
      bookingNoticeTime: $bookingNoticeTime,
      checkInStart: $checkInStart,
      checkInEnd: $checkInEnd,
      maxDaysNotice: $maxDaysNotice,
      minNight: $minNight,
      maxNight: $maxNight,
      basePrice: $basePrice,
      cleaningPrice: $cleaningPrice,
      tax: $tax,
      currency: $currency,
      weeklyDiscount: $weeklyDiscount,
      monthlyDiscount: $monthlyDiscount,
      blockedDates: $blockedDates,
      bookingType: $bookingType,
      cancellationPolicy: $cancellationPolicy,

      guestBasePrice:$guestBasePrice,
      additionalPrice: $additionalPrice,
      visitorsLimit: $visitorsLimit, 
      visitorsPrice: $visitorsPrice, 
      petLimit: $petLimit,
      petPrice: $petPrice,
      infantLimit: $infantLimit,
      infantPrice: $infantPrice
    ) {
      status
    }
  }
`;

const resp = await fetch('/graphql', {
  method: 'post',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    query: query,
    variables
  }),
  credentials: 'include'
});


  const { data } = await resp.json();

  if (data?.updateListingStep3 != undefined) {
    if (data?.updateListingStep3.status == "success") {
      history.push('/become-a-host/' + values.id + '/home');
      await dispatch(getListingDataStep3(values.id));
      await dispatch(manageListingSteps(values.id, 3));
      await dispatch(setLoaderComplete('updateListing'));
    } else if (data?.updateListingStep3.status == "notLoggedIn") {
      dispatch(setLoaderComplete('updateListing'));
      throw new SubmissionError({ _error: messages.notLoggedIn });
    } else {
      dispatch(setLoaderComplete('updateListing'));
      throw new SubmissionError({ _error: messages.somethingWentWrong });
    }
  } else {
    dispatch(setLoaderComplete('updateListing'));
    throw new SubmissionError({ _error: messages.somethingWentWrong });
  }

}

export default updateStep3;





// import { SubmissionError } from 'redux-form';

// // Fetch request
// import fetch from '../../core/fetch';

// // Redux Action
// import { getListingDataStep3 } from '../../actions/getListingDataStep3';
// import { manageListingSteps } from '../../actions/manageListingSteps';
// import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

// // For Redirect
// import history from '../../core/history';
// // Locale
// import messages from '../../locale/messages';

// async function updateStep3(values, dispatch) {

//   let weeklyDiscount = values?.weeklyDiscount || 0;
//   let monthlyDiscount = values?.monthlyDiscount || 0;
//   let cleaningPrice = values?.cleaningPrice || 0;
//   let tax = values?.tax || 0;

//   let id, houseRules, bookingNoticeTime, checkInStart, checkInEnd, maxDaysNotice, minNight, maxNight, basePrice, currency, blockedDates, bookingType, cancellationPolicy,
//     AdditionalPerson, additionalPrice, visitorsLimit, visitorsPrice, petLimit, petPrice, infantLimit, infantPrice;

//   id = values?.id;
//   houseRules = values?.houseRules;
//   bookingNoticeTime = values?.bookingNoticeTime;
//   checkInStart = values?.checkInStart;
//   checkInEnd = values?.checkInEnd;
//   maxDaysNotice = values?.maxDaysNotice;
//   minNight = values?.minNight;
//   maxNight = values?.maxNight;
//   basePrice = values?.basePrice;
//   currency = values?.currency;
//   blockedDates = values?.blockedDates;
//   bookingType = values?.bookingType;
//   cancellationPolicy = values?.cancellationPolicy;

//   // New fields
//   AdditionalPerson = values?.AdditionalPerson;
//   additionalPrice = values?.additionalPrice;
//   visitorsLimit = values?.visitorsLimit;
//   visitorsPrice = values?.visitorsPrice;
//   petLimit = values?.petLimit;
//   petPrice = values?.petPrice;
//   infantLimit = values?.infantLimit;
//   infantPrice = values?.infantPrice;

//   let variables = {
//     id,
//     bookingNoticeTime,
//     checkInStart,
//     checkInEnd,
//     maxDaysNotice,
//     minNight,
//     maxNight,
//     basePrice,
//     cleaningPrice,
//     currency,
//     weeklyDiscount,
//     monthlyDiscount,
//     bookingType,
//     cancellationPolicy,
//     AdditionalPerson,
//     additionalPrice,
//     visitorsLimit,
//     visitorsPrice,
//     petLimit,
//     petPrice,
//     infantLimit,
//     infantPrice
//   };

//   dispatch(setLoaderStart('updateListing'));
//   const query = `query UpdateListingStep4(
//     $id: Int!,
//     $bookingNoticeTime: String,
//     $checkInStart: String,
//     $checkInEnd: String,
//     $maxDaysNotice: String,
//     $minNight: Int,
//     $maxNight: Int,
//     $basePrice: Float,
//     $cleaningPrice: Float,
//     $currency: String,
//     $weeklyDiscount: Int,
//     $monthlyDiscount: Int,
//     $bookingType: String!,
//     $cancellationPolicy: Int,
//     $AdditionalPerson: Int,
//     $additionalPrice: Float,
//     $visitorsLimit: Int,
//     $visitorsPrice: Float,
//     $petLimit: Int,
//     $petPrice: Float,
//     $infantLimit: Int,
//     $infantPrice: Float
//   ) {
//     updateListingStep4(
//       id: $id,
//       bookingNoticeTime: $bookingNoticeTime,
//       checkInStart: $checkInStart,
//       checkInEnd: $checkInEnd,
//       maxDaysNotice: $maxDaysNotice,
//       minNight: $minNight,
//       maxNight: $maxNight,
//       basePrice: $basePrice,
//       cleaningPrice: $cleaningPrice,
//       currency: $currency,
//       weeklyDiscount: $weeklyDiscount,
//       monthlyDiscount: $monthlyDiscount,
//       bookingType: $bookingType,
//       cancellationPolicy: $cancellationPolicy,
//       AdditionalPerson: $AdditionalPerson,
//       additionalPrice: $additionalPrice,
//       visitorsLimit: $visitorsLimit,
//       visitorsPrice: $visitorsPrice,
//       petLimit: $petLimit,
//       petPrice: $petPrice,
//       infantLimit: $infantLimit,
//       infantPrice: $infantPrice
//     ) {
//       status
//     }
//   }`;

//   const resp = await fetch('/graphql', {
//     method: 'post',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       query,
//       variables
//     }),
//     credentials: 'include'
//   });

//   const { data } = await resp.json();

//   if (data?.updateListingStep4 != undefined) {
//     if (data?.updateListingStep4.status === "success") {
//       history.push('/become-a-host/' + values.id + '/home');
//       await dispatch(getListingDataStep3(values.id));
//       await dispatch(manageListingSteps(values.id, 3));
//       await dispatch(setLoaderComplete('updateListing'));
//     } else if (data?.updateListingStep4.status === "notLoggedIn") {
//       dispatch(setLoaderComplete('updateListing'));
//       throw new SubmissionError({ _error: messages.notLoggedIn });
//     } else {
//       dispatch(setLoaderComplete('updateListing'));
//       throw new SubmissionError({ _error: messages.somethingWentWrong });
//     }
//   } else {
//     dispatch(setLoaderComplete('updateListing'));
//     throw new SubmissionError({ _error: messages.somethingWentWrong });
//   }
// }

// export default updateStep3;

